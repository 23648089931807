import axios from 'axios';

export const findAll = (params = {}) => async (dispatch) => {
    try {
        dispatch({ type: 'USER_FINDALL' });
        const res = await axios.get('/user/', { params: params });
        dispatch({
            type: 'USER_FINDALL_SUCCESS',
            payload: res.data
        });
    } catch ({ response }) {
        dispatch({
            type: 'USER_FINDALL_FAIL',
            payload: response.data
        });
    }
}