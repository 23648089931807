import React from 'react';
import { Route, Switch } from 'react-router-dom'
import Login from 'login/Login';
import FirstLogin from 'login/FirstLogin';
import Views from 'views/Views';
import Termos from 'views/termos/Termos';

export const Routes = () => {
    return (
        <Switch>
            <Route exact path={'/login'} component={Login}></Route>
            <Route exact path={'/first-login'} component={FirstLogin}></Route>
            <Route exact path={'/termo'} component={Termos}></Route>
            <Views></Views>
        </Switch>
    );
}