import React from 'react';
import { connect } from 'react-redux';
import { loginAction } from 'actions/login_action';
import { Form, Input, Button, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './Login.scss';



const Login = (props) => {
    const { loginAction } = props

    React.useEffect(() => {
        localStorage.removeItem("token");
    }, [])

    const onLogin = (values) => {
        const email = values.email;
        const password = values.password;
        loginAction(email, password);
    };

    return (
        <div className="login-main">
            <div className="login-center">
                <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '20px' }}>
                    Login
                </h2>
                <Form onFinish={onLogin} className="login-form" initialValues={{ email: '', password: '' }}>
                    <Form.Item name="email" rules={[{ required: true, message: 'Por favor, informe seu email' }]}>
                        <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Informe seu email"
                        />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: 'Por favor, informe sua senha' }]}>
                        <Input prefix={<LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="Informe sua senha"
                        />
                    </Form.Item>
                    {props.login.message &&
                        <Form.Item>
                            <Alert message={props.login.message} type="error" />
                        </Form.Item>
                    }
                    <Form.Item>
                        <Button loading={props.login.isProcessing} type="primary" htmlType="submit" className="login-form-button">
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    login: state.login
})

export default connect(mapStateToProps, { loginAction })(Login);
