import React from 'react';
import { Route, Switch } from "react-router-dom";
import EditLicense from './license/EditLicense';
import ListLicense from './license/ListLicense'


const Robot = (props) => {
    return (
        <Switch>
            <Route exact path="/robot/license/edit" component={EditLicense}></Route>
            <Route exact path="/robot/license" component={ListLicense}></Route>
        </Switch>
    )
}

export default Robot