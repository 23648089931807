import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Home } from './home/Home';
//import { Header, Footer } from 'components';
import Robot from './robot/Robot';


const Views = (props) => {
    if (props.token) {
        return (
            <div style={{ background: '#ECECEC', padding: '30px', height: '100%' }}>
                <div className="layout-main">
                    <Route exact path="/" component={Home}></Route>
                    <Robot></Robot>
                </div>
            </div>
        );
    } else {
        return (
            <Redirect to="/login" />
        )
    }
}

const mapStateToProps = state => ({
    token: state.login.token,
    user: state.login.user
})

export default connect(mapStateToProps, {})(Views);