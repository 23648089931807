import axios from 'axios';
import { saveAs } from 'file-saver';

export const findByKeyAction = (key) => async (dispatch) => {

    const res = await axios.get('/termos/' + key);

    return dispatch({
        type: 'FIND_KEY_SUCCESS',
        payload: res
    })
}

export const acceptByKeyAction = (key) => async (dispatch) => {

    const res = await axios.post('/termos/aceita', { id: key });

    return dispatch({
        type: 'ACCEPT_KEY_SUCCESS',
        payload: res
    })
}

export const downloadByKeyAction = (key) => async (dispatch) => {
    const { data, headers } = await axios.get('/termos/' + key + '/download', { responseType: "blob" });
    const filename = headers['filename'];
    saveAs(data, filename);
}