const INITIAL_STATE = {
    isProcessing: false,
    success: null,
    message: '',
    licenses: [],
    license: {}
}

const userLicenseReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case 'LIST_USERLICENSE_SUCCESS':
            return Object.assign({}, state, {
                success: true,
                message: '',
                licenses: payload.licenses
            });
        case 'LIST_USERLICENSE_FAIL':
            return Object.assign({}, state, {
                success: payload.success,
                message: payload.message,
                licenses: []
            });
        case 'SAVE_USERLICENSE':
            return Object.assign({}, state, {
                isProcessing: true,
                success: false,
                message: '',
                license: {}
            });
        case 'SAVE_USERLICENSE_SUCCESS':
            return Object.assign({}, state, {
                isProcessing: false,
                success: true,
                message: '',
                license: payload.license
            });
        case 'SAVE_USERLICENSE_FAIL':
            return Object.assign({}, state, {
                isProcessing: false,
                success: payload.success,
                message: payload.message,
                license: {}
            });
        case 'EMAIL_USERLICENSE':
            return Object.assign({}, state, {
                isProcessing: true
            });
        case 'EMAIL_USERLICENSE_SUCCESS':
            return Object.assign({}, state, {
                isProcessing: false,
                success: true,
                message: '',
            });
        case 'EMAIL_USERLICENSE_FAIL':
            return Object.assign({}, state, {
                isProcessing: false,
                success: payload.success,
                message: payload.message,
            });
        default:
            return state;
    }
}

export default userLicenseReducer;
