import axios from 'axios';
import { push } from 'connected-react-router'

export const findAllAction = () => async (dispatch) => {

    try {
        const { data } = await axios.get('/user/license');

        dispatch({
            type: 'LIST_USERLICENSE_SUCCESS',
            payload: data
        })
        return data;
    } catch ({ response }) {
        dispatch({
            type: 'LIST_USERLICENSE_FAIL',
            payload: response.data
        });
        throw response.data;
    }
}

export const saveAction = (product, user, free, note) => async (dispatch) => {
    dispatch({ type: 'SAVE_USERLICENSE' })
    try {
        const req = {
            product: product,
            user: user,
            free: free,
            note: note
        }

        const { data } = await axios.post('/user/license', req);

        dispatch({
            type: 'SAVE_USERLICENSE_SUCCESS',
            payload: data
        })
        dispatch(push('/robot/license'));
        return data;
    } catch ({ response }) {
        dispatch({
            type: 'SAVE_USERLICENSE_FAIL',
            payload: response.data
        });
        throw response.data;
    }
}

export const sendEmailAction = (id) => async (dispatch) => {
    dispatch({ type: 'EMAIL_USERLICENSE' })
    try {
        await axios.post('/user/license/' + id + '/send-email', {});
        dispatch({ type: 'EMAIL_USERLICENSE_SUCCESS' })
    } catch ({ response }) {
        dispatch({
            type: 'EMAIL_USERLICENSE_FAIL',
            payload: response.data
        });
        throw response.data;
    }
}