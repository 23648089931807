const INITIAL_STATE = {
    isProcessing: false,
    success: null,
    message: '',
    users: [],
}

const userReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case 'USER_FINDALL':
            return Object.assign({}, state, {
                isProcessing: true
            });
        case 'USER_FINDALL_SUCCESS':
            return Object.assign({}, state, {
                isProcessing: false,
                success: true,
                message: '',
                users: payload.users
            });
        case 'USER_FINDALL_FAIL':
            return Object.assign({}, state, {
                isProcessing: false,
                success: false,
                message: payload.message,
                users: []
            });
        default:
            return state;
    }
}

export default userReducer;
