import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import loginReducer from './login_reducer';
import userLicenseReducer from './user_license_reducer';
import termosReducer from './termos_reducer';
import userReducer from './user_reducer';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    login: loginReducer,
    userLicense: userLicenseReducer,
    termos: termosReducer,
    user: userReducer
});
export default createRootReducer