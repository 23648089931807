const INITIAL_STATE = {
    isProcessing: false,
    success: null,
    message: '',
    user: {},
    token: ''
}

const loginReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case 'LOGIN':
            return Object.assign({}, state, {
                isProcessing: true
            });
        case 'LOGIN_SUCCESS':
            return Object.assign({}, state, {
                isProcessing: false,
                success: true,
                message: '',
                user: payload.user
            });
        case 'LOGIN_FAIL':
            return Object.assign({}, state, {
                isProcessing: false,
                success: false,
                message: payload.message,
                user: {}
            });
        case 'LOGIN_TOKEN':
            return Object.assign({}, state, {
                token: payload.data.token
            });
        case 'SAVE_FIRSTLOGIN_SUCCESS':
            return {};
        case 'SAVE_FIRSTLOGIN_FAIL':
            return Object.assign({}, state, {
                success: false,
                message: payload.data.message
            });
        default:
            return state;
    }
}

export default loginReducer;
