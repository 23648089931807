import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Modal, Result } from 'antd';
import { useLocation } from "react-router-dom";
import { push } from 'connected-react-router';
import 'url-search-params-polyfill';
import { findByKeyAction, downloadByKeyAction, acceptByKeyAction } from 'actions/termos_action';
import './Termos.scss'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const TsPro = () => (
    <div className="main">
        <p><strong>Licenciadora:&nbsp;</strong>Filipe Popenga Geraim MEI, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob nº 28.913.695/0001-11, com sede sito a Av. Sete de Setembro, nº 2775, 9º andar, Coworking Aldeia, Curitiba – Estado do Paraná, neste ato devidamente representada na forma de seu Contrato Social.</p>
        <p><strong>Licenciado:&nbsp;</strong>Aquele que aceita os termos e condições deste Contrato, através de adesão ao serviço prestado.</p>
        <p>I - DEFINIÇÕES:</p>
        <ol>
            <li>
                <p>Anexo I: é anexo integrante do presente Contrato, o qual contém os <u>procedimentos de instalação e manutenção</u> do <strong>ROBÔ PRO TRADE&nbsp;</strong>a serem seguidos pelo Licenciado em seu respectivo computador.</p>
            </li>
            <li>
                <p>Algoritmo: é para efeito deste Contrato, o procedimento computacional programado em linguagem específica, integrante do <strong>ROBÔ PRO TRADE&nbsp;</strong>e destinado a ser utilizado na plataforma de automoção de algoritmo (MT5 – Meta Trader 5) composto por uma gama de parâmetros, cujos respectivos tutoriais foram especialmente desenvolvidos e disponibilizados ao Licenciado para fins de personalização segundo o seu perfil de investidor.</p>
            </li>
            <li>
                <p>CNPJ: Cadastro Nacional da Pessoa Jurídica, expedido pela Receita Federal do Brasil – RFB.</p>
            </li>
            <li>
                <p>Código-Fonte: é a linguagem em formato de alto nível, sem estar compilado para binário.</p>
            </li>
            <li>
                <p>Contas Demo: são contas virtuais de negociação.</p>
            </li>
            <li>
                <p>Licença de Uso: é a outorga concedida pela Licenciadora ao Licenciado, por prazo de vigência determinado, para fins de utilização determinado, para fins de utilização do <strong>ROBÔ PRO TRADE</strong>.</p>
            </li>
            <li>
                <p>Licenciado: é a pessoa física ou jurídica, ou entidade despersonalizada dotada de CPF ou CNPJ que: (i) na qualidade de usuário final do <strong>ROBÔ PRO TRADE</strong>, é signatário do Contrato; e (ii) contratou, junto a sociedades corretoras e distribuidoras de valores mobiliários e demais integrantes do sistema de valores mobiliários nacional e internacional, como condição essencial para a celebração do Contrato, o uso de plataforma de automoção de algoritmos (MT5 – Meta Trader 5)</p>
            </li>
            <li>
                <p>Licenciadora: é a Micro empresa individual<strong>&nbsp;</strong>Filipe Popenga Geraim MEI, inscrita no CNPJ/MF sob nº 28.913.695/0001-11, a qual é a desenvolvedora, do <strong>ROBÔ PRO TRADE&nbsp;</strong>e das estratégias embarcadas, cuja respectiva Licença de uso conferida ao Licenciado é objeto deste Contrato.</p>
            </li>
            <li>
                <p>Manejo de Risco: é a técnica utilizada por trader para definir o risco da operação e o manejo do mesmo frente às oscilações do mercado. Exemplos de parâmetros desse tipo são: stop loss, perda máxima, número máximo de traders ao dia.</p>
            </li>
            <li>
                <p>Parâmetros (GESTÃO DE RISCO): integram os algoritmos, permitindo ao Licenciado, por meio dos tutoriais disponibilizados pela Licenciadora, realizar sua gestão de risco de acordo com seu capital financeiro a serem efetuadas por meio da Plataforma MT5 – Meta Trader 5.</p>
            </li>
            <li>
                <p>Plataforma de Automoção de Algoritmos (MT5 – Meta Trader 5): é uma plataforma de algoritmos atualmente disponibilizada por diversas sociedades corretoras do mercado financeiro, a seus respectivos clientes para efeito de automatização do envio de suas ordens para negociação de ativos financeiros.</p>
            </li>
            <li>
                <p>automático após o algoritmo identificar uma tendência do mercado, os Robôs também esPrazo de Vigência do Contrato: a vigência do Contrato é de 1 (um) ano.</p>
            </li>
            <li>
                <p><strong>ROBÔ PRO TRADE</strong>: é um software desenvolvido pela Licenciadora, de sua propriedade, composto pelo sistema de <strong>ROBÔ PRO TRADE&nbsp;</strong>integrados por um conjunto de Algoritmos de compra e venda automática de ativos financeiros. São disponibilizados pela Licenciadora aos Licenciados por meio de sistemas de tutoriais que permitem aos mesmos personalizarem a gestão de risco pertinentes de acordo com o saldo em conta. O <strong>ROBÔ PRO TRADE&nbsp;</strong>realizam suas entradas nas negociações de modo tabelecem os stops loss (Perda) e Gain (Ganho) de maneira automática, porém os valores financeiros de ganho e perda são definidos pelo licenciado, <strong>não sendo tais valores de responsabilidade da Licenciadora.</strong></p>
            </li>
            <li>
                <p><strong>Este contrato, DE NENHUMA FORMA, representa a prestação, por parte da Licenciadora, a quem quer que seja, DE ATIVIDADE DE GESTÃO DE CARTEIRA DE VALORES MOBILIÁRIOS OU CONSULTORIA DE INVESTIMENTOS.</strong></p>
            </li>
        </ol>
        <p>II – TERMOS E CONDIÇÕES</p>
        <ol type="A">
            <li>
                <p>DO OBJETO</p>
            </li>
        </ol>
        <ol>
            <li>
                <p>A Licenciadora outorga, neste ato, ao Licenciado, pelo prazo de vigência descrito no Campo I do Contrato – “Definições”, o direito de Licença de Uso do<strong>&nbsp;ROBÔ PRO TRADE</strong>.</p>
            </li>
            <li>
                <p>A Licença de Uso é temporária, intransferível e não exclusiva para qualquer versão do <strong>ROBÔ PRO TRADE&nbsp;</strong>fornecidas pela Licenciadora durante o prazo de vigência do contrato.</p>
            </li>
            <li>
                <p>Os direitos autorais, de propriedade intelectual e outros que lhe são adjacentes e decorrentes são e permanecem como sendo de domínio e propriedade única e exclusiva da Licenciadora.</p>
            </li>
        </ol>
        <ol start="2" type="A">
            <li>
                <p>DA PRÉ-CONTRATAÇÃO DA PLATAFORMA DE AUTOMOÇÃO DE ALGORITMOS</p>
            </li>
            <li>
                <p>DA INSTALAÇÃO E UTILIZAÇÃO</p>
            </li>
        </ol>
        <ol>
            <li>
                <p>Cada licença poderá ser utilizada em um único computador, o <strong>ROBÔ PRO TRADE&nbsp;</strong>podem ser utilizados tanto em conta real como em contas demonstrativas.</p>
            </li>
        </ol>
        <p>2. Não é permitida a cessão e/ou transferência, por parte do Licenciado, sob qualquer modalidade, da Licença de uso a quaisquer terceiros, bem como a utilização, por parte de quaisquer terceiros do <strong>ROBÔ PRO TRADE</strong>, sob pena de instauração, por parte da Licenciadora, das medidas judiciais cabíveis nas esferas civis ou criminais.</p>
        <p>3. Não é permitido ao Licenciado replicar ou distribuir o sinal do <strong>ROBÔ PRO TRADE&nbsp;</strong>através de ferramentas de cópia de traders para quaisquer outras contas próprias e/ou de terceiros.</p>
        <ol start="4">
            <li>
                <p>Igualmente não é permitido ao Licenciado:</p>
                <ol type="a">
                    <li>
                        <p>Utilizar o <strong>ROBÔ PRO TRADE&nbsp;</strong>fora das condições estabelecidas na Licença de Uso;</p>
                    </li>
                    <li>
                        <p>Traduzir, fazer engenharia reversa, decompilar, copiar imagens, códigos fonte ou quaisquer partes do <strong>ROBÔ PRO TRADE&nbsp;</strong>para utilização fora do mesmo;</p>
                    </li>
                    <li>
                        <p>Remover ou alterar qualquer marca registrada ou aviso de direito autoral ou de propriedade inseridos no <strong>ROBÔ PRO TRADE</strong>.</p>
                    </li>
                </ol>
            </li>
            <li>
                <p>Em caso de substituição do Licenciado será obrigatória a aquisição de nova licença de uso.</p>
            </li>
            <li>
                <p>É de única e exclusiva responsabilidade do Licenciado a realização fidedigna dos procedimentos de instalação e a manutenção do <strong>ROBÔ PRO TRADE&nbsp;</strong>em seus respectivos computadores, nos termos do Anexo I ao Contrato.</p>
            </li>
            <li>
                <p>O Licenciado declara ter conhecimento que a Licenciadora pode a qualquer tempo se utilizar dos dados oriundos das operações por ele realizadas para produção de estatísticas descritivas com finalidade de elaborar estudos para o desenvolvimento do <strong>ROBÔ PRO TRADE.</strong></p>
            </li>
        </ol>
        <ol start="4" type="A">
            <li>
                <p>DO PREÇO</p>
            </li>
        </ol>
        <ol>
            <li>
                <p>O Licenciado pagará o valor estipulado no modo de adesão ao presente contrato</p>
            </li>
        </ol>
        <ol start="5" type="A">
            <li>
                <p>DO SUPORTE TÉNICO</p>
            </li>
        </ol>
        <ul>
            <ul>
                <ol>
                    <li>
                        <p>A Licenciadora somente prestará suporte técnico ao Licenciado durante o período de vigência do Contrato, de modo que não será prestado qualquer suporte técnico ao Licenciado após o término da vigência do Contrato</p>
                    </li>
                </ol>
            </ul>
        </ul>
        <ol start="6" type="A">
            <li>
                <p>DAS EXCLUDENTES RESPONSABILIDADES DA LICENCIADORA</p>
            </li>
        </ol>
        <ol>
            <li>
                <p>A Licenciadora <strong>NÃO SE RESPONSABILIZARÁ&nbsp;</strong>perante o Licenciado em caso de ocorrência das seguintes hipóteses, as quais são de <strong>responsabilidade exclusiva do Licenciado</strong>, sem prejuízo do disposto no tópico “G” abaixo:</p>
                <ol>
                    <li>
                        <p>Mau uso pelo Licenciado do <strong>ROBÔ PRO TRADE</strong>,desatendimento, pelo Licenciado dos procedimentos de instalação e manutenção contidos no Anexo I ao Contrato; e não atendimento, pelo Licenciado, das orientações indicadas pelo suporte técnico da Licenciadora;</p>
                    </li>
                    <li>
                        <p>Danos causados em razão de vírus de computador, falhas e/ou descontinuidade no fornecimento de energia elétrica e do sistema de telecomunicações;</p>
                    </li>
                    <li>
                        <p>Eventos imprevisíveis e inevitáveis, considerados como de força maior ou caso fortuito, incluindo, mas não se limitando a (a) guerras, hostilidades ou invasões militares, invasões, atos de terrorismo, vandalismos e outros similares; b) hiperinflação, maxidesvalorização monetária e outros similares, de caráter excepcional, c) greves gerais ou se setores específicos, de âmbito regional, nacional ou internacional; e d) atos da natureza e fenômenos meteorológicos de proporções significativas que prejudiquem substancialmente o regular funcionamento do <strong>ROBÔ PRO TRADE</strong>;</p>
                    </li>
                    <li>
                        <p>Funcionamento, atualização, exatidão, disponibilidade ou qualquer outra falha ou descontinuidade que a Plataforma de Automoção de Algoritmos (MT5 – Meta Trader) venha eventualmente a apresentar;</p>
                    </li>
                    <li>
                        <p>Oscilações de preços dos valores mobiliários, ativos financeiros e índices de mercado e moedas, dentre outros, integrantes dos mercados de crédito, financeiro e de capitais de renda fixa e variável quando quaisquer destes sejam objetos das ordens parametrizadas por meio do <strong>ROBÔ PRO TRADE</strong>;</p>
                    </li>
                    <li>
                        <p>Manejo e modificação dos parâmetros pertinentes às execuções de ordens automatizadas por meio da plataforma de automação de algoritmos;</p>
                    </li>
                    <li>
                        <p>Problemas de sleepage ocorridas nas ordens parametrizadas nos termos do Contrato;</p>
                    </li>
                    <li>
                        <p>Problemas de liquidez financeira relacionados às ordens parametrizadas nos termos do Contrato; e</p>
                    </li>
                    <li>
                        <p>Problemas relacionados ao fornecedor de cotações relacionados a inexistência de preços, travamento das cotações, falta de conexão com os servidores de cotação ao fornecedor.</p>
                    </li>
                </ol>
            </li>
            <li>
                <p>A Licenciadora não se responsabiliza por eventuais erros ou imprecisões em cotações ou preços de qualquer natureza que embasem posições no <strong>ROBÔ PRO TRADE&nbsp;</strong>gerado pelo fornecedor das mesmas.</p>
            </li>
            <li>
                <p><strong>O Licenciado DECLARA SUA CIÊNCIA</strong> de que: (i) não há qualquer condição contida no Contrato que configure a prestação, por parte da Licenciadora, das atividades de consultoria e/ou administração de valores mobiliários e; (ii) a Licenciadora não possui qualquer interferência sobre a estratégia, algoritmo ou sinal utilizados para a execução da operação por parte do Licenciado, não garantindo a Licenciadora ao Licenciado o auferimento de quaisquer ganhos ou lucros advindos de suas execuções de ordem parametrizadas de forma alguma por meio do <strong>ROBÔ PRO TRADE.</strong></p>
            </li>
            <li>
                <p><strong>O Licenciado</strong> <strong>DECLARA A SUA CIÊNCIA&nbsp;</strong>acerca da imprevisibilidade e oscilações de preços que caracterizam as transações e execuções de ordens que ocorrem no mercado financeiro, estando, ainda,<strong>&nbsp;CIENTE DE QUE A OCORRÊNCIA DE RENTABILIDADE PASSADA NÃO É GARANTIA DE RENTABILIDADE FUTURA.</strong></p>
            </li>
            <li>
                <p>A Licenciadora não poderá, a qualquer tempo, ser responsabilizada pelos eventuais danos, perdas e prejuízos decorrentes da execução das estratégias inseridas no <strong>ROBÔ PRO TRADE&nbsp;</strong>e/ou gerados pelos riscos assumidos pelos Licenciados ao operar no mercado de capitais nacional ou internacional, bem como pela adequação, ajuste e/ou alteração da estratégia, acompanhamento, parametrização do algoritmo do <strong>ROBÔ PRO TRADE&nbsp;</strong>durante o dia, ao longo do pregão, os quais são de <strong>inteira e exclusiva responsabilidade do Licenciado.</strong></p>
            </li>
            <li>
                <p>A Plataforma de Automoção de Algoritmos, por meio do qual são executadas as ordens de compra e venda do Licenciado parametrizadas nos termo da Licença de uso objeto deste Contrato, são de <strong>responsabilidade do próprio Licenciado</strong>, da sociedade corretora que lhe licenciou a Plataforma de Automoção de Algoritmos e/ou proprietária da Plataforma de Automação de Algoritmos, ficando a Licenciadora isenta de quaisquer responsabilidades no tocante ao mau funcionamento, falhas apresentadas ou descontinuidade da Plataforma de Automoção de Algoritmos, bem como a todos e quaisquer eventos negativos que eventualmente venham a atingir a plataforma de automoção de algoritmos.</p>
            </li>
        </ol>
        <ol start="7" type="A">
            <li>
                <p>DA RESPONSABILIDADE ÚNICA E EXCLUSIVA DO LICENCIADO</p>
            </li>
        </ol>
        <ol>
            <li>
                <p><strong>O LICENCIADO É O ÚNICO E EXCLUSIVO RESPONSÁVEL PELO:</strong></p>
                <ol>
                    <li>
                        <p>Login, senha e manuseio operacional do <strong>ROBÔ PRO TRADE,&nbsp;</strong>não podendo, portanto, alegar o desconhecimento da funcionalidade do mesmo para pleitear qualquer ressarcimento;</p>
                    </li>
                    <li>
                        <p>Seleção dos ativos executados pelo <strong>ROBÔ PRO TRADE</strong>, bem como pela parametrização da gestão de risco dos mesmos;</p>
                    </li>
                    <li>
                        <p>Manutenção de ambiente e equipamentos hábeis a manter o correto funcionamento da Plataforma de Automoção de Algoritmos incluindo, mas não se limitando, à conectividade, energia, atualizações do mesmo e capacidade do terminal utilizado, dentre outros.</p>
                    </li>
                </ol>
            </li>
            <li>
                <p>Cabe única e exclusivamente ao Licenciado configurar a gestão de risco do <strong>ROBÔ PRO TRADE,&nbsp;</strong>para fins de adequado funcionamento.</p>
            </li>
            <li>
                <p>O Licenciado tem a responsabilidade de seguir os procedimentos de instalação e manutenção do <strong>ROBÔ PRO TRADE</strong>, previstos no Anexo I ao Contrato, principalmente no que diz respeito aos tutoriais.</p>
            </li>
            <li>
                <p><strong>O LICENCIADO DECLARA&nbsp;</strong>neste ato de forma expressa e irrevogável, sua <strong>PLENA CIÊNCIA NO SENTIDO DE QUE O LIMITE DE PERDA DE CAPITAL A QUE ESTÁ EXPOSTO&nbsp;</strong>ao utilizar o <strong>ROBÔ PRO TRADE,</strong> <strong>É O MONTANTE TOTAL DE SEU CAPITAL</strong> definido na Plataforma de Automoção de Algoritmos (M5).</p>
            </li>
            <li>
                <p>A <strong>GESTÃO DE LIMITES EM CONTA REAL É DE TOTAL RESPONSABILIDADE DO LICENCIADO</strong>, sendo a definição dos valores de operação e de margem em conta real apenas uma forma de buscar a limitação das perdas máximas <strong>E DE FORMA ALGUMA REPRESENTA UMA GARANTIA OU RECOMENDAÇÃO DA LICENCIADORA.</strong></p>
            </li>
        </ol>
        <p>III - DA RESOLUÇÃO</p>
        <ol>
            <li>
                <p>O presente Contrato é firmado em caráter irrevogável e irretratável, obrigando a Licenciadora, o Licenciado, seus herdeiros e sucessores a qualquer título. Entretanto, ocorrerá a resolução do presente Contrato, além das situações previstas na legislação aplicável, em caso de:</p>
                <ol>
                    <li>
                        <p>Descumprimento de quaisquer cláusulas contratuais por quaisquer das partes;</p>
                    </li>
                    <li>
                        <p>Declaração de insolvência por qualquer das partes ou requerimento de recuperação judicial, pedido de falência, intervenção, liquidação ou dissolução judicial ou extrajudicial de qualquer das partes;</p>
                    </li>
                    <li>
                        <p>Haja vista o caráter irrevogável e irretratável do presente Contrato, não estando ele sujeito à resilição unilateral, estabelecem as Partes que não haverá qualquer restituição de valores pela Licenciada em caso de descontinuidade de uso do <strong>ROBÔ PRO TRADE&nbsp;</strong>pelo Licenciado, o qual seguirá à disposição do Licenciado durante todo o período de vigência do presente Contrato.</p>
                    </li>
                    <li>
                        <p><strong>O LICENCIADO SOMENTE FARÁ JUS À RESTITUIÇÃO DOS VALORES QUITADOS&nbsp;</strong> pela contratação do uso do <strong>ROBÔ PRO TRADE&nbsp;</strong>na hipótese manifestar seu desinteresse na manutenção do presente Contrato no <strong>prazo máximo e improrrogável de 07 (sete) dias corridos, contados da data de sua aquisição</strong>, tendo o Licenciado direito ao recebimento integral através do mesmo meio de pagamento utilizado.</p>
                    </li>
                </ol>
            </li>
        </ol>
        <p>IV – DO FORO</p>
        <p>As partes elegem o foro da Comarca de Curitiba – Estado do Paraná, com exclusão de qualquer outro por mais privilegiado que seja, para dirimir ou apreciar todas e quaisquer questões oriundas do presente Contrato.</p>
        <p><strong>ANEXO I</strong></p>
        <p>CONTRATO DE LICENÇA DE USO DO SOFTWARE</p>
        <p><strong>ROBÔ PRO TRADE&nbsp;</strong></p>
        <p>DA INSTALAÇÃO E UTILIZAÇÃO</p>
        <ol>
            <li>
                <p>Para habilitar e utilizar o <strong>ROBÔ PRO TRADE</strong>, é necessário solicitar a ativação à Licenciadora.</p>
            </li>
            <li>
                <p>Uma vez habilitada a Plataforma de Automoção de Algoritmos (MT5 – Meta Trader 5), o Licenciado poderá utilizá-la em quaisquer contas, demo ou real.</p>
            </li>
            <li>
                <p>Será disponibilizado pela Licenciadora um sistema tutorial, composto por um manual em texto e um minicurso em vídeo que explica como usar a plataforma de automoção de algoritmos, que permitirá configurar o <strong>ROBÔ PRO TRADE.</strong></p>
            </li>
            <li>
                <p>É altamente recomendado que ao Licenciado que utilize um local em uma cloud ou VPS (Virtual Private Server) ou host em ambiente compartilhado, sendo importante registrar que a <strong>Licenciadora em hipótese alguma será responsável pelo serviço VPS</strong>, contratado pelo cliente, inclusive no que diz respeito a esclarecimentos pertinentes às instalações ou utilização deste a qualquer tempo.</p>
            </li>
        </ol>
    </div>
)

const TsTrade = () => (
    <div className="main">
        <p><strong>Licenciadora:&nbsp;</strong>Filipe Popenga Geraim MEI, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob nº 28.913.695/0001-11, com sede sito a Av. Sete de Setembro, nº 2775, 9º andar, Coworking Aldeia, Curitiba – Estado do Paraná, neste ato devidamente representada na forma de seu Contrato Social.</p>
        <p><strong>Licenciado:&nbsp;</strong>Aquele aceita os termos e condições deste Contrato, através de adesão ao serviço prestado.</p>
        <p>I - DEFINIÇÕES:</p>
        <ol>
            <li>
                <p>Anexo I: é anexo integrante do presente Contrato, o qual contém os <u>procedimentos de instalação e manutenção</u> do <strong>ROBÔ TS TRADE&nbsp;</strong>a serem seguidos pelo Licenciado em seu respectivo computador.</p>
            </li>
            <li>
                <p>Algoritmo: é para efeito deste Contrato, o procedimento computacional programado em linguagem específica, integrante do <strong>ROBÔ TS TRADE</strong> e destinado a ser utilizado na plataforma de automoção de algoritmo (MT5 – Meta Trader 5) composto por uma gama de parâmetros, cujos respectivos tutoriais foram especialmente desenvolvidos e disponibilizados ao Licenciado para fins de personalização segundo o seu perfil de investidor.</p>
            </li>
            <li>
                <p>CNPJ: Cadastro Nacional da Pessoa Jurídica, expedido pela Receita Federal do Brasil – RFB.</p>
            </li>
            <li>
                <p>Código-Fonte: é a linguagem em formato de alto nível, sem estar compilado para binário.</p>
            </li>
            <li>
                <p>Contas Demo: são contas virtuais de negociação.</p>
            </li>
            <li>
                <p>Licença de Uso: é a outorga concedida pela Licenciadora ao Licenciado, por prazo de vigência determinado, para fins de utilização determinado, para fins de utilização do <strong>ROBÔ TS TRADE</strong>.</p>
            </li>
            <li>
                <p>Licenciado: é a pessoa física ou jurídica, ou entidade despersonalizada dotada de CPF ou CNPJ que: (i) na qualidade de usuário final do <strong>ROBÔ TS TRADE</strong> , é signatário do Contrato; e (ii) contratou, junto a sociedades corretoras e distribuidoras de valores mobiliários e demais integrantes do sistema de valores mobiliários nacional e internacional, como condição essencial para a celebração do Contrato, o uso de plataforma de automoção de algoritmos (MT5 – Meta Trader 5)</p>
            </li>
            <li>
                <p>Licenciadora: é a Micro empresa individual<strong>&nbsp;</strong>Filipe Popenga Geraim MEI, inscrita no CNPJ/MF sob nº 28.913.695/0001-11, a qual é a desenvolvedora, do <strong>ROBÔ TS TRADE</strong> e das estratégias embarcadas, cuja respectiva Licença de uso conferida ao Licenciado é objeto deste Contrato.</p>
            </li>
            <li>
                <p>Manejo de Risco: é a técnica utilizada por trader para definir o risco da operação e o manejo do mesmo frente às oscilações do mercado. Exemplos de parâmetros desse tipo são: stop loss, perda máxima, número máximo de traders ao dia.</p>
            </li>
            <li>
                <p>Parâmetros (GESTÃO DE RISCO): integram os algoritmos, permitindo ao Licenciado, por meio dos tutoriais disponibilizados pela Licenciadora, realizar sua gestão de risco de acordo com seu capital financeiro a serem efetuadas por meio da Plataforma MT5 – Meta Trader 5.</p>
            </li>
            <li>
                <p>Plataforma de Automoção de Algoritmos (MT5 – Meta Trader 5): é uma plataforma de algoritmos atualmente disponibilizada por diversas sociedades corretoras do mercado financeiro, a seus respectivos clientes para efeito de automatização do envio de suas ordens para negociação de ativos financeiros.</p>
            </li>
            <li>
                <p>automático após o algoritmo identificar uma tendência do mercado, os Robôs também esPrazo de Vigência do Contrato: a vigência do Contrato é de 1 (um) ano.</p>
            </li>
            <li>
                <p><strong>ROBÔ TS TRADE</strong> : é um software desenvolvido pela Licenciadora, de sua propriedade, composto pelo sistema de <strong>ROBÔ TS TRADE</strong> integrados por um conjunto de Algoritmos de compra e venda automática de ativos financeiros. São disponibilizados pela Licenciadora aos Licenciados por meio de sistemas de tutoriais que permitem aos mesmos personalizarem a gestão de risco pertinentes de acordo com o saldo em conta. O <strong>ROBÔ TS TRADE</strong> realizam suas entradas nas negociações de modo tabelecem os stops loss (Perda) e Gain (Ganho) de maneira automática, porém os valores financeiros de ganho e perda são definidos pelo licenciado, <strong>não sendo tais valores de responsabilidade da Licenciadora.</strong></p>
            </li>
            <li>
                <p><strong>Este contrato, DE NENHUMA FORMA, representa a prestação, por parte da Licenciadora, a quem quer que seja, DE ATIVIDADE DE GESTÃO DE CARTEIRA DE VALORES MOBILIÁRIOS OU CONSULTORIA DE INVESTIMENTOS.</strong></p>
            </li>
        </ol>
        <p>II – TERMOS E CONDIÇÕES</p>
        <ol type="A">
            <li>
                <p>DO OBJETO</p>
            </li>
        </ol>
        <ol>
            <li>
                <p>A Licenciadora outorga, neste ato, ao Licenciado, pelo prazo de vigência descrito no Campo I do Contrato – “Definições”, o direito de Licença de Uso do <strong>ROBÔ TS TRADE</strong>.</p>
            </li>
            <li>
                <p>A Licença de Uso é temporária, intransferível e não exclusiva para qualquer versão do <strong>ROBÔ TS TRADE</strong> fornecidas pela Licenciadora durante o prazo de vigência do contrato.</p>
            </li>
            <li>
                <p>Os direitos autorais, de propriedade intelectual e outros que lhe são adjacentes e decorrentes são e permanecem como sendo de domínio e propriedade única e exclusiva da Licenciadora.</p>
            </li>
        </ol>
        <ol start="2" type="A">
            <li>
                <p>DA PRÉ-CONTRATAÇÃO DA PLATAFORMA DE AUTOMOÇÃO DE ALGORITMOS</p>
            </li>
            <li>
                <p>DA INSTALAÇÃO E UTILIZAÇÃO</p>
            </li>
        </ol>
        <ol>
            <li>
                <p>Cada licença poderá ser utilizada em um único computador, o <strong>ROBÔ TS TRADE</strong> podem ser utilizados tanto em conta real como em contas demonstrativas.</p>
            </li>
        </ol>
        <p>2. Não é permitida a cessão e/ou transferência, por parte do Licenciado, sob qualquer modalidade, da Licença de uso a quaisquer terceiros, bem como a utilização, por parte de quaisquer terceiros do <strong>ROBÔ TS TRADE</strong>, sob pena de instauração, por parte da Licenciadora, das medidas judiciais cabíveis nas esferas civis ou criminais.</p>
        <p>3. Não é permitido ao Licenciado replicar ou distribuir o sinal do <strong>ROBÔ TS TRADE</strong> através de ferramentas de cópia de traders para quaisquer outras contas próprias e/ou de terceiros.</p>
        <ol start="4">
            <li>
                <p>Igualmente não é permitido ao Licenciado:</p>
                <ol type="a">
                    <li>
                        <p>Utilizar o <strong>ROBÔ TS TRADE</strong> fora das condições estabelecidas na Licença de Uso;</p>
                    </li>
                    <li>
                        <p>Traduzir, fazer engenharia reversa, decompilar, copiar imagens, códigos fonte ou quaisquer partes do <strong>ROBÔ TS TRADE</strong> para utilização fora do mesmo;</p>
                    </li>
                    <li>
                        <p>Remover ou alterar qualquer marca registrada ou aviso de direito autoral ou de propriedade inseridos no <strong>ROBÔ TS TRADE</strong>.</p>
                    </li>
                </ol>
            </li>
            <li>
                <p>Em caso de substituição do Licenciado será obrigatória a aquisição de nova licença de uso.</p>
            </li>
            <li>
                <p>É de única e exclusiva responsabilidade do Licenciado a realização fidedigna dos procedimentos de instalação e a manutenção do <strong>ROBÔ TS TRADE</strong> em seus respectivos computadores, nos termos do Anexo I ao Contrato.</p>
            </li>
            <li>
                <p>O Licenciado declara ter conhecimento que a Licenciadora pode a qualquer tempo se utilizar dos dados oriundos das operações por ele realizadas para produção de estatísticas descritivas com finalidade de elaborar estudos para o desenvolvimento do <strong>ROBÔ TS TRADE.</strong></p>
            </li>
        </ol>
        <ol start="4" type="A">
            <li>
                <p>DO PREÇO</p>
            </li>
            <li>
                <p>O Licenciado pagará o valor estipulado no modo de adesão ao presente contrato de licenciamento.</p>
            </li>
            <li>
                <p>DO SUPORTE TÉNICO</p>
            </li>
        </ol>
        <ul>
            <ul>
                <ol>
                    <li>
                        <p>A Licenciadora somente prestará suporte técnico ao Licenciado durante o período de vigência do Contrato, de modo que não será prestado qualquer suporte técnico ao Licenciado após o término da vigência do Contrato</p>
                    </li>
                </ol>
            </ul>
        </ul>
        <ol start="7" type="A">
            <li>
                <p>DAS EXCLUDENTES RESPONSABILIDADES DA LICENCIADORA</p>
            </li>
        </ol>
        <ol>
            <li>
                <p>A Licenciadora <strong>NÃO SE RESPONSABILIZARÁ&nbsp;</strong>perante o Licenciado em caso de ocorrência das seguintes hipóteses, as quais são de <strong>responsabilidade exclusiva do Licenciado</strong>, sem prejuízo do disposto no tópico “G” abaixo:</p>
                <ol>
                    <li>
                        <p>Mau uso pelo Licenciado do <strong>ROBÔ TS TRADE</strong>,desatendimento, pelo Licenciado dos procedimentos de instalação e manutenção contidos no Anexo I ao Contrato; e não atendimento, pelo Licenciado, das orientações indicadas pelo suporte técnico da Licenciadora;</p>
                    </li>
                    <li>
                        <p>Danos causados em razão de vírus de computador, falhas e/ou descontinuidade no fornecimento de energia elétrica e do sistema de telecomunicações;</p>
                    </li>
                    <li>
                        <p>Eventos imprevisíveis e inevitáveis, considerados como de força maior ou caso fortuito, incluindo, mas não se limitando a (a) guerras, hostilidades ou invasões militares, invasões, atos de terrorismo, vandalismos e outros similares; b) hiperinflação, maxidesvalorização monetária e outros similares, de caráter excepcional, c) greves gerais ou se setores específicos, de âmbito regional, nacional ou internacional; e d) atos da natureza e fenômenos meteorológicos de proporções significativas que prejudiquem substancialmente o regular funcionamento do <strong>ROBÔ TS TRADE</strong>;</p>
                    </li>
                    <li>
                        <p>Funcionamento, atualização, exatidão, disponibilidade ou qualquer outra falha ou descontinuidade que a Plataforma de Automoção de Algoritmos (MT5 – Meta Trader) venha eventualmente a apresentar;</p>
                    </li>
                    <li>
                        <p>Oscilações de preços dos valores mobiliários, ativos financeiros e índices de mercado e moedas, dentre outros, integrantes dos mercados de crédito, financeiro e de capitais de renda fixa e variável quando quaisquer destes sejam objetos das ordens parametrizadas por meio do <strong>ROBÔ TS TRADE</strong>;</p>
                    </li>
                    <li>
                        <p>Manejo e modificação dos parâmetros pertinentes às execuções de ordens automatizadas por meio da plataforma de automação de algoritmos;</p>
                    </li>
                    <li>
                        <p>Problemas de sleepage ocorridas nas ordens parametrizadas nos termos do Contrato;</p>
                    </li>
                    <li>
                        <p>Problemas de liquidez financeira relacionados às ordens parametrizadas nos termos do Contrato; e</p>
                    </li>
                    <li>
                        <p>Problemas relacionados ao fornecedor de cotações relacionados a inexistência de preços, travamento das cotações, falta de conexão com os servidores de cotação ao fornecedor.</p>
                    </li>
                </ol>
            </li>
            <li>
                <p>A Licenciadora não se responsabiliza por eventuais erros ou imprecisões em cotações ou preços de qualquer natureza que embasem posições no <strong>ROBÔ TS TRADE</strong> <strong>&nbsp;</strong>gerado pelo fornecedor das mesmas.</p>
            </li>
            <li>
                <p><strong>O Licenciado DECLARA SUA CIÊNCIA</strong> de que: (i) não há qualquer condição contida no Contrato que configure a prestação, por parte da Licenciadora, das atividades de consultoria e/ou administração de valores mobiliários e; (ii) a Licenciadora não possui qualquer interferência sobre a estratégia, algoritmo ou sinal utilizados para a execução da operação por parte do Licenciado, não garantindo a Licenciadora ao Licenciado o auferimento de quaisquer ganhos ou lucros advindos de suas execuções de ordem parametrizadas de forma alguma por meio do <strong>ROBÔ TS TRADE</strong>.</p>
            </li>
            <li>
                <p><strong>O Licenciado</strong> <strong>DECLARA A SUA CIÊNCIA&nbsp;</strong>acerca da imprevisibilidade e oscilações de preços que caracterizam as transações e execuções de ordens que ocorrem no mercado financeiro, estando, ainda,<strong>&nbsp;CIENTE DE QUE A OCORRÊNCIA DE RENTABILIDADE PASSADA NÃO É GARANTIA DE RENTABILIDADE FUTURA.</strong></p>
            </li>
            <li>
                <p>A Licenciadora não poderá, a qualquer tempo, ser responsabilizada pelos eventuais danos, perdas e prejuízos decorrentes da execução das estratégias inseridas no <strong>ROBÔ TS TRADE</strong> e/ou gerados pelos riscos assumidos pelos Licenciados ao operar no mercado de capitais nacional ou internacional, bem como pela adequação, ajuste e/ou alteração da estratégia, acompanhamento, parametrização do algoritmo do <strong>ROBÔ TS TRADE</strong> durante o dia, ao longo do pregão, os quais são de <strong>inteira e exclusiva responsabilidade do Licenciado.</strong></p>
            </li>
            <li>
                <p>A Plataforma de Automoção de Algoritmos, por meio do qual são executadas as ordens de compra e venda do Licenciado parametrizadas nos termo da Licença de uso objeto deste Contrato, são de <strong>responsabilidade do próprio Licenciado</strong>, da sociedade corretora que lhe licenciou a Plataforma de Automoção de Algoritmos e/ou proprietária da Plataforma de Automação de Algoritmos, ficando a Licenciadora isenta de quaisquer responsabilidades no tocante ao mau funcionamento, falhas apresentadas ou descontinuidade da Plataforma de Automoção de Algoritmos, bem como a todos e quaisquer eventos negativos que eventualmente venham a atingir a plataforma de automoção de algoritmos.</p>
            </li>
        </ol>
        <ol start="8" type="A">
            <li>
                <p>DA RESPONSABILIDADE ÚNICA E EXCLUSIVA DO LICENCIADO</p>
            </li>
        </ol>
        <ol>
            <li>
                <p><strong>O LICENCIADO É O ÚNICO E EXCLUSIVO RESPONSÁVEL PELO:</strong></p>
                <ol>
                    <li>
                        <p>Login, senha e manuseio operacional do <strong>ROBÔ TS TRADE,&nbsp;</strong>não podendo, portanto, alegar o desconhecimento da funcionalidade do mesmo para pleitear qualquer ressarcimento;</p>
                    </li>
                    <li>
                        <p>Seleção dos ativos executados pelo <strong>ROBÔ TS TRADE</strong>, bem como pela parametrização da gestão de risco dos mesmos;</p>
                    </li>
                    <li>
                        <p>Manutenção de ambiente e equipamentos hábeis a manter o correto funcionamento da Plataforma de Automoção de Algoritmos incluindo, mas não se limitando, à conectividade, energia, atualizações do mesmo e capacidade do terminal utilizado, dentre outros.</p>
                    </li>
                </ol>
            </li>
            <li>
                <p>Cabe única e exclusivamente ao Licenciado configurar a gestão de risco do <strong>ROBÔ TS TRADE</strong> <strong>,&nbsp;</strong>para fins de adequado funcionamento.</p>
            </li>
            <li>
                <p>O Licenciado tem a responsabilidade de seguir os procedimentos de instalação e manutenção do <strong>ROBÔ TS TRADE</strong>, previstos no Anexo I ao Contrato, principalmente no que diz respeito aos tutoriais.</p>
            </li>
            <li>
                <p><strong>O LICENCIADO DECLARA&nbsp;</strong>neste ato de forma expressa e irrevogável, sua <strong>PLENA CIÊNCIA NO SENTIDO DE QUE O LIMITE DE PERDA DE CAPITAL A QUE ESTÁ EXPOSTO&nbsp;</strong>ao utilizar o <strong>ROBÔ TS TRADE,</strong> <strong>É O MONTANTE TOTAL DE SEU CAPITAL</strong> definido na Plataforma de Automoção de Algoritmos (M5).</p>
            </li>
            <li>
                <p>A <strong>GESTÃO DE LIMITES EM CONTA REAL É DE TOTAL RESPONSABILIDADE DO LICENCIADO</strong>, sendo a definição dos valores de operação e de margem em conta real apenas uma forma de buscar a limitação das perdas máximas <strong>E DE FORMA ALGUMA REPRESENTA UMA GARANTIA OU RECOMENDAÇÃO DA LICENCIADORA.</strong></p>
            </li>
        </ol>
        <p>III - DA RESOLUÇÃO</p>
        <ol>
            <li>
                <p>O presente Contrato é firmado em caráter irrevogável e irretratável, obrigando a Licenciadora, o Licenciado, seus herdeiros e sucessores a qualquer título. Entretanto, ocorrerá a resolução do presente Contrato, além das situações previstas na legislação aplicável, em caso de:</p>
                <ol>
                    <li>
                        <p>Descumprimento de quaisquer cláusulas contratuais por quaisquer das partes;</p>
                    </li>
                    <li>
                        <p>Declaração de insolvência por qualquer das partes ou requerimento de recuperação judicial, pedido de falência, intervenção, liquidação ou dissolução judicial ou extrajudicial de qualquer das partes;</p>
                    </li>
                    <li>
                        <p>Haja vista o caráter irrevogável e irretratável do presente Contrato, não estando ele sujeito à resilição unilateral, estabelecem as Partes que não haverá qualquer restituição de valores pela Licenciada em caso de descontinuidade de uso do <strong>ROBÔ TS TRADE</strong> pelo Licenciado, o qual seguirá à disposição do Licenciado durante todo o período de vigência do presente Contrato.</p>
                    </li>
                    <li>
                        <p><strong>O LICENCIADO SOMENTE FARÁ JUS À RESTITUIÇÃO DOS VALORES QUITADOS&nbsp;</strong> pela contratação do uso do <strong>ROBÔ TS TRADE</strong> na hipótese manifestar seu desinteresse na manutenção do presente Contrato no <strong>prazo máximo e improrrogável de 07 (sete) dias corridos, contados da data de sua aquisição</strong>, tendo o Licenciado direito ao recebimento integral através do mesmo meio de pagamento utilizado.</p>
                    </li>
                </ol>
            </li>
        </ol>
        <p>IV – DO FORO</p>
        <p>As partes elegem o foro da Comarca de Curitiba – Estado do Paraná, com exclusão de qualquer outro por mais privilegiado que seja, para dirimir ou apreciar todas e quaisquer questões oriundas do presente Contrato.</p>
        <p><strong>ANEXO I</strong></p>
        <p>CONTRATO DE LICENÇA DE USO DO SOFTWARE</p>
        <p><strong>ROBÔ TS TRADE</strong></p>
        <p>DA INSTALAÇÃO E UTILIZAÇÃO</p>
        <ol>
            <li>
                <p>Para habilitar e utilizar o <strong>ROBÔ TS TRADE</strong>, é necessário solicitar a ativação à Licenciadora.</p>
            </li>
            <li>
                <p>Uma vez habilitada a Plataforma de Automoção de Algoritmos (MT5 – Meta Trader 5), o Licenciado poderá utilizá-la em quaisquer contas, demo ou real.</p>
            </li>
            <li>
                <p>Será disponibilizado pela Licenciadora um sistema tutorial, composto por um manual em texto e um minicurso em vídeo que explica como usar a plataforma de automoção de algoritmos, que permitirá configurar o <strong>ROBÔ TS TRADE.</strong></p>
            </li>
            <li>
                <p>É altamente recomendado que ao Licenciado que utilize um local em uma cloud ou VPS (Virtual Private Server) ou host em ambiente compartilhado, sendo importante registrar que a <strong>Licenciadora em hipótese alguma será responsável pelo serviço VPS</strong>, contratado pelo cliente, inclusive no que diz respeito a esclarecimentos pertinentes às instalações ou utilização deste a qualquer tempo.</p>
            </li>
        </ol>
    </div>
)

const OnBott = () => (
    <div className="main">
        <p><strong>Licenciadora:&nbsp;</strong>Filipe Popenga Geraim MEI, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob nº 28.913.695/0001-11, com sede sito a Av. Sete de Setembro, nº 2775, 9º andar, Coworking Aldeia, Curitiba – Estado do Paraná, neste ato devidamente representada na forma de seu Contrato Social.</p>
        <p><strong>Licenciado:&nbsp;</strong>Aquele aceita os termos e condições deste Contrato, através de adesão ao serviço prestado.</p>
        <p>I - DEFINIÇÕES:</p>
        <ol>
            <li>
                <p>Anexo I: é anexo integrante do presente Contrato, o qual contém os <u>procedimentos de instalação e manutenção</u> do <strong>ROBÔ ONBOTT&nbsp;</strong>a serem seguidos pelo Licenciado em seu respectivo computador.</p>
            </li>
            <li>
                <p>Algoritmo: é para efeito deste Contrato, o procedimento computacional programado em linguagem específica, integrante do <strong>ROBÔ ONBOTT</strong> e destinado a ser utilizado na plataforma de automoção de algoritmo (MT5 – Meta Trader 5) composto por uma gama de parâmetros, cujos respectivos tutoriais foram especialmente desenvolvidos e disponibilizados ao Licenciado para fins de personalização segundo o seu perfil de investidor.</p>
            </li>
            <li>
                <p>CNPJ: Cadastro Nacional da Pessoa Jurídica, expedido pela Receita Federal do Brasil – RFB.</p>
            </li>
            <li>
                <p>Código-Fonte: é a linguagem em formato de alto nível, sem estar compilado para binário.</p>
            </li>
            <li>
                <p>Contas Demo: são contas virtuais de negociação.</p>
            </li>
            <li>
                <p>Licença de Uso: é a outorga concedida pela Licenciadora ao Licenciado, por prazo de vigência determinado, para fins de utilização determinado, para fins de utilização do <strong>ROBÔ ONBOTT</strong>.</p>
            </li>
            <li>
                <p>Licenciado: é a pessoa física ou jurídica, ou entidade despersonalizada dotada de CPF ou CNPJ que: (i) na qualidade de usuário final do <strong>ROBÔ ONBOTT</strong> , é signatário do Contrato; e (ii) contratou, junto a sociedades corretoras e distribuidoras de valores mobiliários e demais integrantes do sistema de valores mobiliários nacional e internacional, como condição essencial para a celebração do Contrato, o uso de plataforma de automoção de algoritmos (MT5 – Meta Trader 5)</p>
            </li>
            <li>
                <p>Licenciadora: é a Micro empresa individual<strong>&nbsp;</strong>Filipe Popenga Geraim MEI, inscrita no CNPJ/MF sob nº 28.913.695/0001-11, a qual é a desenvolvedora, do <strong>ROBÔ ONBOTT</strong> e das estratégias embarcadas, cuja respectiva Licença de uso conferida ao Licenciado é objeto deste Contrato.</p>
            </li>
            <li>
                <p>Manejo de Risco: é a técnica utilizada por trader para definir o risco da operação e o manejo do mesmo frente às oscilações do mercado. Exemplos de parâmetros desse tipo são: stop loss, perda máxima, número máximo de traders ao dia.</p>
            </li>
            <li>
                <p>Parâmetros (GESTÃO DE RISCO): integram os algoritmos, permitindo ao Licenciado, por meio dos tutoriais disponibilizados pela Licenciadora, realizar sua gestão de risco de acordo com seu capital financeiro a serem efetuadas por meio da Plataforma MT5 – Meta Trader 5.</p>
            </li>
            <li>
                <p>Plataforma de Automoção de Algoritmos (MT5 – Meta Trader 5): é uma plataforma de algoritmos atualmente disponibilizada por diversas sociedades corretoras do mercado financeiro, a seus respectivos clientes para efeito de automatização do envio de suas ordens para negociação de ativos financeiros.</p>
            </li>
            <li>
                <p>automático após o algoritmo identificar uma tendência do mercado, os Robôs também esPrazo de Vigência do Contrato: a vigência do Contrato é de 1 (um) ano.</p>
            </li>
            <li>
                <p><strong>ROBÔ ONBOTT</strong> : é um software desenvolvido pela Licenciadora, de sua propriedade, composto pelo sistema de <strong>ROBÔ ONBOTT</strong> integrados por um conjunto de Algoritmos de compra e venda automática de ativos financeiros. São disponibilizados pela Licenciadora aos Licenciados por meio de sistemas de tutoriais que permitem aos mesmos personalizarem a gestão de risco pertinentes de acordo com o saldo em conta. O <strong>ROBÔ ONBOTT</strong> realizam suas entradas nas negociações de modo tabelecem os stops loss (Perda) e Gain (Ganho) de maneira automática, porém os valores financeiros de ganho e perda são definidos pelo licenciado, <strong>não sendo tais valores de responsabilidade da Licenciadora.</strong></p>
            </li>
            <li>
                <p><strong>Este contrato, DE NENHUMA FORMA, representa a prestação, por parte da Licenciadora, a quem quer que seja, DE ATIVIDADE DE GESTÃO DE CARTEIRA DE VALORES MOBILIÁRIOS OU CONSULTORIA DE INVESTIMENTOS.</strong></p>
            </li>
        </ol>
        <p>II – TERMOS E CONDIÇÕES</p>
        <ol type="A">
            <li>
                <p>DO OBJETO</p>
            </li>
        </ol>
        <ol>
            <li>
                <p>A Licenciadora outorga, neste ato, ao Licenciado, pelo prazo de vigência descrito no Campo I do Contrato – “Definições”, o direito de Licença de Uso do <strong>ROBÔ ONBOTT</strong>.</p>
            </li>
            <li>
                <p>A Licença de Uso é temporária, intransferível e não exclusiva para qualquer versão do <strong>ROBÔ ONBOTT</strong> fornecidas pela Licenciadora durante o prazo de vigência do contrato.</p>
            </li>
            <li>
                <p>Os direitos autorais, de propriedade intelectual e outros que lhe são adjacentes e decorrentes são e permanecem como sendo de domínio e propriedade única e exclusiva da Licenciadora.</p>
            </li>
        </ol>
        <ol start="2" type="A">
            <li>
                <p>DA PRÉ-CONTRATAÇÃO DA PLATAFORMA DE AUTOMOÇÃO DE ALGORITMOS</p>
            </li>
            <li>
                <p>DA INSTALAÇÃO E UTILIZAÇÃO</p>
            </li>
        </ol>
        <ol>
            <li>
                <p>Cada licença poderá ser utilizada em um único computador, o <strong>ROBÔ ONBOTT</strong> podem ser utilizados tanto em conta real como em contas demonstrativas.</p>
            </li>
        </ol>
        <p>2. Não é permitida a cessão e/ou transferência, por parte do Licenciado, sob qualquer modalidade, da Licença de uso a quaisquer terceiros, bem como a utilização, por parte de quaisquer terceiros do <strong>ROBÔ ONBOTT</strong>, sob pena de instauração, por parte da Licenciadora, das medidas judiciais cabíveis nas esferas civis ou criminais.</p>
        <p>3. Não é permitido ao Licenciado replicar ou distribuir o sinal do <strong>ROBÔ ONBOTT</strong> através de ferramentas de cópia de traders para quaisquer outras contas próprias e/ou de terceiros.</p>
        <ol start="4">
            <li>
                <p>Igualmente não é permitido ao Licenciado:</p>
                <ol type="a">
                    <li>
                        <p>Utilizar o <strong>ROBÔ ONBOTT</strong> fora das condições estabelecidas na Licença de Uso;</p>
                    </li>
                    <li>
                        <p>Traduzir, fazer engenharia reversa, decompilar, copiar imagens, códigos fonte ou quaisquer partes do <strong>ROBÔ ONBOTT</strong> para utilização fora do mesmo;</p>
                    </li>
                    <li>
                        <p>Remover ou alterar qualquer marca registrada ou aviso de direito autoral ou de propriedade inseridos no <strong>ROBÔ ONBOTT</strong>.</p>
                    </li>
                </ol>
            </li>
            <li>
                <p>Em caso de substituição do Licenciado será obrigatória a aquisição de nova licença de uso.</p>
            </li>
            <li>
                <p>É de única e exclusiva responsabilidade do Licenciado a realização fidedigna dos procedimentos de instalação e a manutenção do <strong>ROBÔ ONBOTT</strong> em seus respectivos computadores, nos termos do Anexo I ao Contrato.</p>
            </li>
            <li>
                <p>O Licenciado declara ter conhecimento que a Licenciadora pode a qualquer tempo se utilizar dos dados oriundos das operações por ele realizadas para produção de estatísticas descritivas com finalidade de elaborar estudos para o desenvolvimento do <strong>ROBÔ ONBOTT.</strong></p>
            </li>
        </ol>
        <ol start="4" type="A">
            <li>
                <p>DO PREÇO</p>
            </li>
            <li>
                <p>O Licenciado pagará o valor estipulado no modo de adesão ao presente contrato de licenciamento.</p>
            </li>
            <li>
                <p>DO SUPORTE TÉNICO</p>
            </li>
        </ol>
        <ul>
            <ul>
                <ol>
                    <li>
                        <p>A Licenciadora somente prestará suporte técnico ao Licenciado durante o período de vigência do Contrato, de modo que não será prestado qualquer suporte técnico ao Licenciado após o término da vigência do Contrato</p>
                    </li>
                </ol>
            </ul>
        </ul>
        <ol start="7" type="A">
            <li>
                <p>DAS EXCLUDENTES RESPONSABILIDADES DA LICENCIADORA</p>
            </li>
        </ol>
        <ol>
            <li>
                <p>A Licenciadora <strong>NÃO SE RESPONSABILIZARÁ&nbsp;</strong>perante o Licenciado em caso de ocorrência das seguintes hipóteses, as quais são de <strong>responsabilidade exclusiva do Licenciado</strong>, sem prejuízo do disposto no tópico “G” abaixo:</p>
                <ol>
                    <li>
                        <p>Mau uso pelo Licenciado do <strong>ROBÔ ONBOTT</strong>,desatendimento, pelo Licenciado dos procedimentos de instalação e manutenção contidos no Anexo I ao Contrato; e não atendimento, pelo Licenciado, das orientações indicadas pelo suporte técnico da Licenciadora;</p>
                    </li>
                    <li>
                        <p>Danos causados em razão de vírus de computador, falhas e/ou descontinuidade no fornecimento de energia elétrica e do sistema de telecomunicações;</p>
                    </li>
                    <li>
                        <p>Eventos imprevisíveis e inevitáveis, considerados como de força maior ou caso fortuito, incluindo, mas não se limitando a (a) guerras, hostilidades ou invasões militares, invasões, atos de terrorismo, vandalismos e outros similares; b) hiperinflação, maxidesvalorização monetária e outros similares, de caráter excepcional, c) greves gerais ou se setores específicos, de âmbito regional, nacional ou internacional; e d) atos da natureza e fenômenos meteorológicos de proporções significativas que prejudiquem substancialmente o regular funcionamento do <strong>ROBÔ ONBOTT</strong>;</p>
                    </li>
                    <li>
                        <p>Funcionamento, atualização, exatidão, disponibilidade ou qualquer outra falha ou descontinuidade que a Plataforma de Automoção de Algoritmos (MT5 – Meta Trader) venha eventualmente a apresentar;</p>
                    </li>
                    <li>
                        <p>Oscilações de preços dos valores mobiliários, ativos financeiros e índices de mercado e moedas, dentre outros, integrantes dos mercados de crédito, financeiro e de capitais de renda fixa e variável quando quaisquer destes sejam objetos das ordens parametrizadas por meio do <strong>ROBÔ ONBOTT</strong>;</p>
                    </li>
                    <li>
                        <p>Manejo e modificação dos parâmetros pertinentes às execuções de ordens automatizadas por meio da plataforma de automação de algoritmos;</p>
                    </li>
                    <li>
                        <p>Problemas de sleepage ocorridas nas ordens parametrizadas nos termos do Contrato;</p>
                    </li>
                    <li>
                        <p>Problemas de liquidez financeira relacionados às ordens parametrizadas nos termos do Contrato; e</p>
                    </li>
                    <li>
                        <p>Problemas relacionados ao fornecedor de cotações relacionados a inexistência de preços, travamento das cotações, falta de conexão com os servidores de cotação ao fornecedor.</p>
                    </li>
                </ol>
            </li>
            <li>
                <p>A Licenciadora não se responsabiliza por eventuais erros ou imprecisões em cotações ou preços de qualquer natureza que embasem posições no <strong>ROBÔ ONBOTT</strong> <strong>&nbsp;</strong>gerado pelo fornecedor das mesmas.</p>
            </li>
            <li>
                <p><strong>O Licenciado DECLARA SUA CIÊNCIA</strong> de que: (i) não há qualquer condição contida no Contrato que configure a prestação, por parte da Licenciadora, das atividades de consultoria e/ou administração de valores mobiliários e; (ii) a Licenciadora não possui qualquer interferência sobre a estratégia, algoritmo ou sinal utilizados para a execução da operação por parte do Licenciado, não garantindo a Licenciadora ao Licenciado o auferimento de quaisquer ganhos ou lucros advindos de suas execuções de ordem parametrizadas de forma alguma por meio do <strong>ROBÔ ONBOTT</strong>.</p>
            </li>
            <li>
                <p><strong>O Licenciado</strong> <strong>DECLARA A SUA CIÊNCIA&nbsp;</strong>acerca da imprevisibilidade e oscilações de preços que caracterizam as transações e execuções de ordens que ocorrem no mercado financeiro, estando, ainda,<strong>&nbsp;CIENTE DE QUE A OCORRÊNCIA DE RENTABILIDADE PASSADA NÃO É GARANTIA DE RENTABILIDADE FUTURA.</strong></p>
            </li>
            <li>
                <p>A Licenciadora não poderá, a qualquer tempo, ser responsabilizada pelos eventuais danos, perdas e prejuízos decorrentes da execução das estratégias inseridas no <strong>ROBÔ ONBOTT</strong> e/ou gerados pelos riscos assumidos pelos Licenciados ao operar no mercado de capitais nacional ou internacional, bem como pela adequação, ajuste e/ou alteração da estratégia, acompanhamento, parametrização do algoritmo do <strong>ROBÔ ONBOTT</strong> durante o dia, ao longo do pregão, os quais são de <strong>inteira e exclusiva responsabilidade do Licenciado.</strong></p>
            </li>
            <li>
                <p>A Plataforma de Automoção de Algoritmos, por meio do qual são executadas as ordens de compra e venda do Licenciado parametrizadas nos termo da Licença de uso objeto deste Contrato, são de <strong>responsabilidade do próprio Licenciado</strong>, da sociedade corretora que lhe licenciou a Plataforma de Automoção de Algoritmos e/ou proprietária da Plataforma de Automação de Algoritmos, ficando a Licenciadora isenta de quaisquer responsabilidades no tocante ao mau funcionamento, falhas apresentadas ou descontinuidade da Plataforma de Automoção de Algoritmos, bem como a todos e quaisquer eventos negativos que eventualmente venham a atingir a plataforma de automoção de algoritmos.</p>
            </li>
        </ol>
        <ol start="8" type="A">
            <li>
                <p>DA RESPONSABILIDADE ÚNICA E EXCLUSIVA DO LICENCIADO</p>
            </li>
        </ol>
        <ol>
            <li>
                <p><strong>O LICENCIADO É O ÚNICO E EXCLUSIVO RESPONSÁVEL PELO:</strong></p>
                <ol>
                    <li>
                        <p>Login, senha e manuseio operacional do <strong>ROBÔ ONBOTT,&nbsp;</strong>não podendo, portanto, alegar o desconhecimento da funcionalidade do mesmo para pleitear qualquer ressarcimento;</p>
                    </li>
                    <li>
                        <p>Seleção dos ativos executados pelo <strong>ROBÔ ONBOTT</strong>, bem como pela parametrização da gestão de risco dos mesmos;</p>
                    </li>
                    <li>
                        <p>Manutenção de ambiente e equipamentos hábeis a manter o correto funcionamento da Plataforma de Automoção de Algoritmos incluindo, mas não se limitando, à conectividade, energia, atualizações do mesmo e capacidade do terminal utilizado, dentre outros.</p>
                    </li>
                </ol>
            </li>
            <li>
                <p>Cabe única e exclusivamente ao Licenciado configurar a gestão de risco do <strong>ROBÔ ONBOTT</strong> <strong>,&nbsp;</strong>para fins de adequado funcionamento.</p>
            </li>
            <li>
                <p>O Licenciado tem a responsabilidade de seguir os procedimentos de instalação e manutenção do <strong>ROBÔ ONBOTT</strong>, previstos no Anexo I ao Contrato, principalmente no que diz respeito aos tutoriais.</p>
            </li>
            <li>
                <p><strong>O LICENCIADO DECLARA&nbsp;</strong>neste ato de forma expressa e irrevogável, sua <strong>PLENA CIÊNCIA NO SENTIDO DE QUE O LIMITE DE PERDA DE CAPITAL A QUE ESTÁ EXPOSTO&nbsp;</strong>ao utilizar o <strong>ROBÔ ONBOTT,</strong> <strong>É O MONTANTE TOTAL DE SEU CAPITAL</strong> definido na Plataforma de Automoção de Algoritmos (M5).</p>
            </li>
            <li>
                <p>A <strong>GESTÃO DE LIMITES EM CONTA REAL É DE TOTAL RESPONSABILIDADE DO LICENCIADO</strong>, sendo a definição dos valores de operação e de margem em conta real apenas uma forma de buscar a limitação das perdas máximas <strong>E DE FORMA ALGUMA REPRESENTA UMA GARANTIA OU RECOMENDAÇÃO DA LICENCIADORA.</strong></p>
            </li>
        </ol>
        <p>III - DA RESOLUÇÃO</p>
        <ol>
            <li>
                <p>O presente Contrato é firmado em caráter irrevogável e irretratável, obrigando a Licenciadora, o Licenciado, seus herdeiros e sucessores a qualquer título. Entretanto, ocorrerá a resolução do presente Contrato, além das situações previstas na legislação aplicável, em caso de:</p>
                <ol>
                    <li>
                        <p>Descumprimento de quaisquer cláusulas contratuais por quaisquer das partes;</p>
                    </li>
                    <li>
                        <p>Declaração de insolvência por qualquer das partes ou requerimento de recuperação judicial, pedido de falência, intervenção, liquidação ou dissolução judicial ou extrajudicial de qualquer das partes;</p>
                    </li>
                    <li>
                        <p>Haja vista o caráter irrevogável e irretratável do presente Contrato, não estando ele sujeito à resilição unilateral, estabelecem as Partes que não haverá qualquer restituição de valores pela Licenciada em caso de descontinuidade de uso do <strong>ROBÔ ONBOTT</strong> pelo Licenciado, o qual seguirá à disposição do Licenciado durante todo o período de vigência do presente Contrato.</p>
                    </li>
                    <li>
                        <p><strong>O LICENCIADO SOMENTE FARÁ JUS À RESTITUIÇÃO DOS VALORES QUITADOS&nbsp;</strong> pela contratação do uso do <strong>ROBÔ ONBOTT</strong> na hipótese manifestar seu desinteresse na manutenção do presente Contrato no <strong>prazo máximo e improrrogável de 07 (sete) dias corridos, contados da data de sua aquisição</strong>, tendo o Licenciado direito ao recebimento integral através do mesmo meio de pagamento utilizado.</p>
                    </li>
                </ol>
            </li>
        </ol>
        <p>IV – DO FORO</p>
        <p>As partes elegem o foro da Comarca de Curitiba – Estado do Paraná, com exclusão de qualquer outro por mais privilegiado que seja, para dirimir ou apreciar todas e quaisquer questões oriundas do presente Contrato.</p>
        <p><strong>ANEXO I</strong></p>
        <p>CONTRATO DE LICENÇA DE USO DO SOFTWARE</p>
        <p><strong>ROBÔ ONBOTT</strong></p>
        <p>DA INSTALAÇÃO E UTILIZAÇÃO</p>
        <ol>
            <li>
                <p>Para habilitar e utilizar o <strong>ROBÔ ONBOTT</strong>, é necessário solicitar a ativação à Licenciadora.</p>
            </li>
            <li>
                <p>Uma vez habilitada a Plataforma de Automoção de Algoritmos (MT5 – Meta Trader 5), o Licenciado poderá utilizá-la em quaisquer contas, demo ou real.</p>
            </li>
            <li>
                <p>Será disponibilizado pela Licenciadora um sistema tutorial, composto por um manual em texto e um minicurso em vídeo que explica como usar a plataforma de automoção de algoritmos, que permitirá configurar o <strong>ROBÔ ONBOTT.</strong></p>
            </li>
            <li>
                <p>É altamente recomendado que ao Licenciado que utilize um local em uma cloud ou VPS (Virtual Private Server) ou host em ambiente compartilhado, sendo importante registrar que a <strong>Licenciadora em hipótese alguma será responsável pelo serviço VPS</strong>, contratado pelo cliente, inclusive no que diz respeito a esclarecimentos pertinentes às instalações ou utilização deste a qualquer tempo.</p>
            </li>
        </ol>
    </div>
)

const InBott = () => (
    <div className="main">
        <p><strong>Licenciadora:&nbsp;</strong>Filipe Popenga Geraim MEI, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob nº 28.913.695/0001-11, com sede sito a Av. Sete de Setembro, nº 2775, 9º andar, Coworking Aldeia, Curitiba – Estado do Paraná, neste ato devidamente representada na forma de seu Contrato Social.</p>
        <p><strong>Licenciado:&nbsp;</strong>Aquele aceita os termos e condições deste Contrato, através de adesão ao serviço prestado.</p>
        <p>I - DEFINIÇÕES:</p>
        <ol>
            <li>
                <p>Anexo I: é anexo integrante do presente Contrato, o qual contém os <u>procedimentos de instalação e manutenção</u> do <strong>ROBÔ INBOTT&nbsp;</strong>a serem seguidos pelo Licenciado em seu respectivo computador.</p>
            </li>
            <li>
                <p>Algoritmo: é para efeito deste Contrato, o procedimento computacional programado em linguagem específica, integrante do <strong>ROBÔ INBOTT</strong> e destinado a ser utilizado na plataforma de automoção de algoritmo (MT5 – Meta Trader 5) composto por uma gama de parâmetros, cujos respectivos tutoriais foram especialmente desenvolvidos e disponibilizados ao Licenciado para fins de personalização segundo o seu perfil de investidor.</p>
            </li>
            <li>
                <p>CNPJ: Cadastro Nacional da Pessoa Jurídica, expedido pela Receita Federal do Brasil – RFB.</p>
            </li>
            <li>
                <p>Código-Fonte: é a linguagem em formato de alto nível, sem estar compilado para binário.</p>
            </li>
            <li>
                <p>Contas Demo: são contas virtuais de negociação.</p>
            </li>
            <li>
                <p>Licença de Uso: é a outorga concedida pela Licenciadora ao Licenciado, por prazo de vigência determinado, para fins de utilização determinado, para fins de utilização do <strong>ROBÔ INBOTT</strong>.</p>
            </li>
            <li>
                <p>Licenciado: é a pessoa física ou jurídica, ou entidade despersonalizada dotada de CPF ou CNPJ que: (i) na qualidade de usuário final do <strong>ROBÔ INBOTT</strong> , é signatário do Contrato; e (ii) contratou, junto a sociedades corretoras e distribuidoras de valores mobiliários e demais integrantes do sistema de valores mobiliários nacional e internacional, como condição essencial para a celebração do Contrato, o uso de plataforma de automoção de algoritmos (MT5 – Meta Trader 5)</p>
            </li>
            <li>
                <p>Licenciadora: é a Micro empresa individual<strong>&nbsp;</strong>Filipe Popenga Geraim MEI, inscrita no CNPJ/MF sob nº 28.913.695/0001-11, a qual é a desenvolvedora, do <strong>ROBÔ INBOTT</strong> e das estratégias embarcadas, cuja respectiva Licença de uso conferida ao Licenciado é objeto deste Contrato.</p>
            </li>
            <li>
                <p>Manejo de Risco: é a técnica utilizada por trader para definir o risco da operação e o manejo do mesmo frente às oscilações do mercado. Exemplos de parâmetros desse tipo são: stop loss, perda máxima, número máximo de traders ao dia.</p>
            </li>
            <li>
                <p>Parâmetros (GESTÃO DE RISCO): integram os algoritmos, permitindo ao Licenciado, por meio dos tutoriais disponibilizados pela Licenciadora, realizar sua gestão de risco de acordo com seu capital financeiro a serem efetuadas por meio da Plataforma MT5 – Meta Trader 5.</p>
            </li>
            <li>
                <p>Plataforma de Automoção de Algoritmos (MT5 – Meta Trader 5): é uma plataforma de algoritmos atualmente disponibilizada por diversas sociedades corretoras do mercado financeiro, a seus respectivos clientes para efeito de automatização do envio de suas ordens para negociação de ativos financeiros.</p>
            </li>
            <li>
                <p>automático após o algoritmo identificar uma tendência do mercado, os Robôs também esPrazo de Vigência do Contrato: a vigência do Contrato é de 1 (um) ano.</p>
            </li>
            <li>
                <p><strong>ROBÔ INBOTT</strong> : é um software desenvolvido pela Licenciadora, de sua propriedade, composto pelo sistema de <strong>ROBÔ INBOTT</strong> integrados por um conjunto de Algoritmos de compra e venda automática de ativos financeiros. São disponibilizados pela Licenciadora aos Licenciados por meio de sistemas de tutoriais que permitem aos mesmos personalizarem a gestão de risco pertinentes de acordo com o saldo em conta. O <strong>ROBÔ INBOTT</strong> realizam suas entradas nas negociações de modo tabelecem os stops loss (Perda) e Gain (Ganho) de maneira automática, porém os valores financeiros de ganho e perda são definidos pelo licenciado, <strong>não sendo tais valores de responsabilidade da Licenciadora.</strong></p>
            </li>
            <li>
                <p><strong>Este contrato, DE NENHUMA FORMA, representa a prestação, por parte da Licenciadora, a quem quer que seja, DE ATIVIDADE DE GESTÃO DE CARTEIRA DE VALORES MOBILIÁRIOS OU CONSULTORIA DE INVESTIMENTOS.</strong></p>
            </li>
        </ol>
        <p>II – TERMOS E CONDIÇÕES</p>
        <ol type="A">
            <li>
                <p>DO OBJETO</p>
            </li>
        </ol>
        <ol>
            <li>
                <p>A Licenciadora outorga, neste ato, ao Licenciado, pelo prazo de vigência descrito no Campo I do Contrato – “Definições”, o direito de Licença de Uso do <strong>ROBÔ INBOTT</strong>.</p>
            </li>
            <li>
                <p>A Licença de Uso é temporária, intransferível e não exclusiva para qualquer versão do <strong>ROBÔ INBOTT</strong> fornecidas pela Licenciadora durante o prazo de vigência do contrato.</p>
            </li>
            <li>
                <p>Os direitos autorais, de propriedade intelectual e outros que lhe são adjacentes e decorrentes são e permanecem como sendo de domínio e propriedade única e exclusiva da Licenciadora.</p>
            </li>
        </ol>
        <ol start="2" type="A">
            <li>
                <p>DA PRÉ-CONTRATAÇÃO DA PLATAFORMA DE AUTOMOÇÃO DE ALGORITMOS</p>
            </li>
            <li>
                <p>DA INSTALAÇÃO E UTILIZAÇÃO</p>
            </li>
        </ol>
        <ol>
            <li>
                <p>Cada licença poderá ser utilizada em um único computador, o <strong>ROBÔ INBOTT</strong> podem ser utilizados tanto em conta real como em contas demonstrativas.</p>
            </li>
        </ol>
        <p>2. Não é permitida a cessão e/ou transferência, por parte do Licenciado, sob qualquer modalidade, da Licença de uso a quaisquer terceiros, bem como a utilização, por parte de quaisquer terceiros do <strong>ROBÔ INBOTT</strong>, sob pena de instauração, por parte da Licenciadora, das medidas judiciais cabíveis nas esferas civis ou criminais.</p>
        <p>3. Não é permitido ao Licenciado replicar ou distribuir o sinal do <strong>ROBÔ INBOTT</strong> através de ferramentas de cópia de traders para quaisquer outras contas próprias e/ou de terceiros.</p>
        <ol start="4">
            <li>
                <p>Igualmente não é permitido ao Licenciado:</p>
                <ol type="a">
                    <li>
                        <p>Utilizar o <strong>ROBÔ INBOTT</strong> fora das condições estabelecidas na Licença de Uso;</p>
                    </li>
                    <li>
                        <p>Traduzir, fazer engenharia reversa, decompilar, copiar imagens, códigos fonte ou quaisquer partes do <strong>ROBÔ INBOTT</strong> para utilização fora do mesmo;</p>
                    </li>
                    <li>
                        <p>Remover ou alterar qualquer marca registrada ou aviso de direito autoral ou de propriedade inseridos no <strong>ROBÔ INBOTT</strong>.</p>
                    </li>
                </ol>
            </li>
            <li>
                <p>Em caso de substituição do Licenciado será obrigatória a aquisição de nova licença de uso.</p>
            </li>
            <li>
                <p>É de única e exclusiva responsabilidade do Licenciado a realização fidedigna dos procedimentos de instalação e a manutenção do <strong>ROBÔ INBOTT</strong> em seus respectivos computadores, nos termos do Anexo I ao Contrato.</p>
            </li>
            <li>
                <p>O Licenciado declara ter conhecimento que a Licenciadora pode a qualquer tempo se utilizar dos dados oriundos das operações por ele realizadas para produção de estatísticas descritivas com finalidade de elaborar estudos para o desenvolvimento do <strong>ROBÔ INBOTT.</strong></p>
            </li>
        </ol>
        <ol start="4" type="A">
            <li>
                <p>DO PREÇO</p>
            </li>
            <li>
                <p>O Licenciado pagará o valor estipulado no modo de adesão ao presente contrato de licenciamento.</p>
            </li>
            <li>
                <p>DO SUPORTE TÉNICO</p>
            </li>
        </ol>
        <ul>
            <ul>
                <ol>
                    <li>
                        <p>A Licenciadora somente prestará suporte técnico ao Licenciado durante o período de vigência do Contrato, de modo que não será prestado qualquer suporte técnico ao Licenciado após o término da vigência do Contrato</p>
                    </li>
                </ol>
            </ul>
        </ul>
        <ol start="7" type="A">
            <li>
                <p>DAS EXCLUDENTES RESPONSABILIDADES DA LICENCIADORA</p>
            </li>
        </ol>
        <ol>
            <li>
                <p>A Licenciadora <strong>NÃO SE RESPONSABILIZARÁ&nbsp;</strong>perante o Licenciado em caso de ocorrência das seguintes hipóteses, as quais são de <strong>responsabilidade exclusiva do Licenciado</strong>, sem prejuízo do disposto no tópico “G” abaixo:</p>
                <ol>
                    <li>
                        <p>Mau uso pelo Licenciado do <strong>ROBÔ INBOTT</strong>,desatendimento, pelo Licenciado dos procedimentos de instalação e manutenção contidos no Anexo I ao Contrato; e não atendimento, pelo Licenciado, das orientações indicadas pelo suporte técnico da Licenciadora;</p>
                    </li>
                    <li>
                        <p>Danos causados em razão de vírus de computador, falhas e/ou descontinuidade no fornecimento de energia elétrica e do sistema de telecomunicações;</p>
                    </li>
                    <li>
                        <p>Eventos imprevisíveis e inevitáveis, considerados como de força maior ou caso fortuito, incluindo, mas não se limitando a (a) guerras, hostilidades ou invasões militares, invasões, atos de terrorismo, vandalismos e outros similares; b) hiperinflação, maxidesvalorização monetária e outros similares, de caráter excepcional, c) greves gerais ou se setores específicos, de âmbito regional, nacional ou internacional; e d) atos da natureza e fenômenos meteorológicos de proporções significativas que prejudiquem substancialmente o regular funcionamento do <strong>ROBÔ INBOTT</strong>;</p>
                    </li>
                    <li>
                        <p>Funcionamento, atualização, exatidão, disponibilidade ou qualquer outra falha ou descontinuidade que a Plataforma de Automoção de Algoritmos (MT5 – Meta Trader) venha eventualmente a apresentar;</p>
                    </li>
                    <li>
                        <p>Oscilações de preços dos valores mobiliários, ativos financeiros e índices de mercado e moedas, dentre outros, integrantes dos mercados de crédito, financeiro e de capitais de renda fixa e variável quando quaisquer destes sejam objetos das ordens parametrizadas por meio do <strong>ROBÔ INBOTT</strong>;</p>
                    </li>
                    <li>
                        <p>Manejo e modificação dos parâmetros pertinentes às execuções de ordens automatizadas por meio da plataforma de automação de algoritmos;</p>
                    </li>
                    <li>
                        <p>Problemas de sleepage ocorridas nas ordens parametrizadas nos termos do Contrato;</p>
                    </li>
                    <li>
                        <p>Problemas de liquidez financeira relacionados às ordens parametrizadas nos termos do Contrato; e</p>
                    </li>
                    <li>
                        <p>Problemas relacionados ao fornecedor de cotações relacionados a inexistência de preços, travamento das cotações, falta de conexão com os servidores de cotação ao fornecedor.</p>
                    </li>
                </ol>
            </li>
            <li>
                <p>A Licenciadora não se responsabiliza por eventuais erros ou imprecisões em cotações ou preços de qualquer natureza que embasem posições no <strong>ROBÔ INBOTT</strong> <strong>&nbsp;</strong>gerado pelo fornecedor das mesmas.</p>
            </li>
            <li>
                <p><strong>O Licenciado DECLARA SUA CIÊNCIA</strong> de que: (i) não há qualquer condição contida no Contrato que configure a prestação, por parte da Licenciadora, das atividades de consultoria e/ou administração de valores mobiliários e; (ii) a Licenciadora não possui qualquer interferência sobre a estratégia, algoritmo ou sinal utilizados para a execução da operação por parte do Licenciado, não garantindo a Licenciadora ao Licenciado o auferimento de quaisquer ganhos ou lucros advindos de suas execuções de ordem parametrizadas de forma alguma por meio do <strong>ROBÔ INBOTT</strong>.</p>
            </li>
            <li>
                <p><strong>O Licenciado</strong> <strong>DECLARA A SUA CIÊNCIA&nbsp;</strong>acerca da imprevisibilidade e oscilações de preços que caracterizam as transações e execuções de ordens que ocorrem no mercado financeiro, estando, ainda,<strong>&nbsp;CIENTE DE QUE A OCORRÊNCIA DE RENTABILIDADE PASSADA NÃO É GARANTIA DE RENTABILIDADE FUTURA.</strong></p>
            </li>
            <li>
                <p>A Licenciadora não poderá, a qualquer tempo, ser responsabilizada pelos eventuais danos, perdas e prejuízos decorrentes da execução das estratégias inseridas no <strong>ROBÔ INBOTT</strong> e/ou gerados pelos riscos assumidos pelos Licenciados ao operar no mercado de capitais nacional ou internacional, bem como pela adequação, ajuste e/ou alteração da estratégia, acompanhamento, parametrização do algoritmo do <strong>ROBÔ INBOTT</strong> durante o dia, ao longo do pregão, os quais são de <strong>inteira e exclusiva responsabilidade do Licenciado.</strong></p>
            </li>
            <li>
                <p>A Plataforma de Automoção de Algoritmos, por meio do qual são executadas as ordens de compra e venda do Licenciado parametrizadas nos termo da Licença de uso objeto deste Contrato, são de <strong>responsabilidade do próprio Licenciado</strong>, da sociedade corretora que lhe licenciou a Plataforma de Automoção de Algoritmos e/ou proprietária da Plataforma de Automação de Algoritmos, ficando a Licenciadora isenta de quaisquer responsabilidades no tocante ao mau funcionamento, falhas apresentadas ou descontinuidade da Plataforma de Automoção de Algoritmos, bem como a todos e quaisquer eventos negativos que eventualmente venham a atingir a plataforma de automoção de algoritmos.</p>
            </li>
        </ol>
        <ol start="8" type="A">
            <li>
                <p>DA RESPONSABILIDADE ÚNICA E EXCLUSIVA DO LICENCIADO</p>
            </li>
        </ol>
        <ol>
            <li>
                <p><strong>O LICENCIADO É O ÚNICO E EXCLUSIVO RESPONSÁVEL PELO:</strong></p>
                <ol>
                    <li>
                        <p>Login, senha e manuseio operacional do <strong>ROBÔ INBOTT,&nbsp;</strong>não podendo, portanto, alegar o desconhecimento da funcionalidade do mesmo para pleitear qualquer ressarcimento;</p>
                    </li>
                    <li>
                        <p>Seleção dos ativos executados pelo <strong>ROBÔ INBOTT</strong>, bem como pela parametrização da gestão de risco dos mesmos;</p>
                    </li>
                    <li>
                        <p>Manutenção de ambiente e equipamentos hábeis a manter o correto funcionamento da Plataforma de Automoção de Algoritmos incluindo, mas não se limitando, à conectividade, energia, atualizações do mesmo e capacidade do terminal utilizado, dentre outros.</p>
                    </li>
                </ol>
            </li>
            <li>
                <p>Cabe única e exclusivamente ao Licenciado configurar a gestão de risco do <strong>ROBÔ INBOTT</strong> <strong>,&nbsp;</strong>para fins de adequado funcionamento.</p>
            </li>
            <li>
                <p>O Licenciado tem a responsabilidade de seguir os procedimentos de instalação e manutenção do <strong>ROBÔ INBOTT</strong>, previstos no Anexo I ao Contrato, principalmente no que diz respeito aos tutoriais.</p>
            </li>
            <li>
                <p><strong>O LICENCIADO DECLARA&nbsp;</strong>neste ato de forma expressa e irrevogável, sua <strong>PLENA CIÊNCIA NO SENTIDO DE QUE O LIMITE DE PERDA DE CAPITAL A QUE ESTÁ EXPOSTO&nbsp;</strong>ao utilizar o <strong>ROBÔ INBOTT,</strong> <strong>É O MONTANTE TOTAL DE SEU CAPITAL</strong> definido na Plataforma de Automoção de Algoritmos (M5).</p>
            </li>
            <li>
                <p>A <strong>GESTÃO DE LIMITES EM CONTA REAL É DE TOTAL RESPONSABILIDADE DO LICENCIADO</strong>, sendo a definição dos valores de operação e de margem em conta real apenas uma forma de buscar a limitação das perdas máximas <strong>E DE FORMA ALGUMA REPRESENTA UMA GARANTIA OU RECOMENDAÇÃO DA LICENCIADORA.</strong></p>
            </li>
        </ol>
        <p>III - DA RESOLUÇÃO</p>
        <ol>
            <li>
                <p>O presente Contrato é firmado em caráter irrevogável e irretratável, obrigando a Licenciadora, o Licenciado, seus herdeiros e sucessores a qualquer título. Entretanto, ocorrerá a resolução do presente Contrato, além das situações previstas na legislação aplicável, em caso de:</p>
                <ol>
                    <li>
                        <p>Descumprimento de quaisquer cláusulas contratuais por quaisquer das partes;</p>
                    </li>
                    <li>
                        <p>Declaração de insolvência por qualquer das partes ou requerimento de recuperação judicial, pedido de falência, intervenção, liquidação ou dissolução judicial ou extrajudicial de qualquer das partes;</p>
                    </li>
                    <li>
                        <p>Haja vista o caráter irrevogável e irretratável do presente Contrato, não estando ele sujeito à resilição unilateral, estabelecem as Partes que não haverá qualquer restituição de valores pela Licenciada em caso de descontinuidade de uso do <strong>ROBÔ INBOTT</strong> pelo Licenciado, o qual seguirá à disposição do Licenciado durante todo o período de vigência do presente Contrato.</p>
                    </li>
                    <li>
                        <p><strong>O LICENCIADO SOMENTE FARÁ JUS À RESTITUIÇÃO DOS VALORES QUITADOS&nbsp;</strong> pela contratação do uso do <strong>ROBÔ INBOTT</strong> na hipótese manifestar seu desinteresse na manutenção do presente Contrato no <strong>prazo máximo e improrrogável de 07 (sete) dias corridos, contados da data de sua aquisição</strong>, tendo o Licenciado direito ao recebimento integral através do mesmo meio de pagamento utilizado.</p>
                    </li>
                </ol>
            </li>
        </ol>
        <p>IV – DO FORO</p>
        <p>As partes elegem o foro da Comarca de Curitiba – Estado do Paraná, com exclusão de qualquer outro por mais privilegiado que seja, para dirimir ou apreciar todas e quaisquer questões oriundas do presente Contrato.</p>
        <p><strong>ANEXO I</strong></p>
        <p>CONTRATO DE LICENÇA DE USO DO SOFTWARE</p>
        <p><strong>ROBÔ INBOTT</strong></p>
        <p>DA INSTALAÇÃO E UTILIZAÇÃO</p>
        <ol>
            <li>
                <p>Para habilitar e utilizar o <strong>ROBÔ INBOTT</strong>, é necessário solicitar a ativação à Licenciadora.</p>
            </li>
            <li>
                <p>Uma vez habilitada a Plataforma de Automoção de Algoritmos (MT5 – Meta Trader 5), o Licenciado poderá utilizá-la em quaisquer contas, demo ou real.</p>
            </li>
            <li>
                <p>Será disponibilizado pela Licenciadora um sistema tutorial, composto por um manual em texto e um minicurso em vídeo que explica como usar a plataforma de automoção de algoritmos, que permitirá configurar o <strong>ROBÔ INBOTT.</strong></p>
            </li>
            <li>
                <p>É altamente recomendado que ao Licenciado que utilize um local em uma cloud ou VPS (Virtual Private Server) ou host em ambiente compartilhado, sendo importante registrar que a <strong>Licenciadora em hipótese alguma será responsável pelo serviço VPS</strong>, contratado pelo cliente, inclusive no que diz respeito a esclarecimentos pertinentes às instalações ou utilização deste a qualquer tempo.</p>
            </li>
        </ol>
    </div>
)

const Termos = (props) => {
    const urlSearch = useQuery();
    const [key, setKey] = useState('');
    const [visible, setVisible] = useState(false);
    const { findByKeyAction, downloadByKeyAction, acceptByKeyAction, push } = props;

    useEffect(() => {
        if (urlSearch.get('chave')) {
            setKey(urlSearch.get('chave'))
        } else {
            push('/login')
        };
    }, [urlSearch, push])

    useEffect(() => {
        if (key.length > 0) {
            findByKeyAction(key);
        }
    }, [key, findByKeyAction])

    useEffect(() => {
        if (props.license && props.license.terms) {
            setVisible(true);
        }
    }, [props.license])

    const onAccept = () => {
        acceptByKeyAction(key);
    }

    const onDownload = () => {
        downloadByKeyAction(key);;
    }

    return (
        <div>
            <div>
                {props.license?.product.name === 'PRO Trade' &&
                    <TsPro></TsPro>
                }
                {props.license?.product.name === 'TS Trade' &&
                    <TsTrade></TsTrade>
                }
                {props.license?.product.name === 'OnBott' &&
                    <OnBott></OnBott>
                }
                {props.license?.product.name === 'InBott' &&
                    <InBott></InBott>
                }
            </div>
            <div className="footer-fixed">
                <Row gutter={40} type="flex" justify="center">
                    <Col span={12} style={{ textAlign: "center" }}>
                        <Button type="default" style={{ marginRight: '30px', width: 200 }}>NÃO ACEITAR</Button>
                        <Button type="primary" style={{ width: 200 }} onClick={onAccept}>ACEITAR</Button>
                    </Col>
                </Row>
            </div>
            <Modal
                width={800}
                title="Confirmação"
                visible={visible}
                closable={false}
                footer={
                    <Button type="primary" style={{ width: 200 }} onClick={onDownload}>Download</Button>
                }
            >
                <Result
                    status="success"
                    title="Obrigado por adquirir um de nossos robôs."
                />
            </Modal>
        </div>
    )
}

const mapStateToProps = state => ({
    license: state.termos.license
})

export default connect(mapStateToProps, { findByKeyAction, downloadByKeyAction, acceptByKeyAction, push })(Termos);