import React, { useState } from 'react';
import { Form, Card, Button, Input, Row, Col, Select, Alert, AutoComplete, Checkbox } from 'antd';
import { SaveOutlined, RollbackOutlined } from '@ant-design/icons';
import { InputMask } from 'components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { saveAction } from 'actions/user_license_action';
import { findAll } from 'actions/user_action';

const EditLicense = (props) => {
    const [noteFree, setNoteFree] = useState(false);
    const [form] = Form.useForm();
    const { saveAction, push, findAll } = props;
    const { users } = props;

    const onSave = async (e) => {
        let user = {
            name: e.name,
            email: e.email,
            phone: e.phone
        };

        saveAction(e.product, user, e.free, e.note);
    }

    const handleSubmit = (values) => {
        onSave(values);
    }

    const getUser = (email) => {
        const x = users.filter(x => x.email === email);
        return x.length > 0 ? x[0] : null;
    }

    const setValuesUser = (user) => {
        if (user) {
            form.setFieldsValue({ name: user.name, phone: user.phone });
        } else {
            form.setFieldsValue({ name: '', phone: '' });
        }
    }

    const onSearchEmail = e => {
        findAll({ email: e });
    };

    const onSelectEmail = (e) => {
        setValuesUser(getUser(e));
    };

    const onChangeEmail = e => {
        setValuesUser(getUser(e));
    };

    const onChangeFree = e => {
        setNoteFree(e.target.checked);
    };

    return (
        <Row gutter={40} type="flex" justify="center">
            <Col span={12}>
                <Card title="Nova Licença" bordered={false} >
                    <Form form={form} layout="vertical" onFinish={handleSubmit} initialValues={{ free: false }}>
                        <Row gutter={40} type="flex" justify="center">
                            <Col span={12}>
                                <Form.Item name="email" label="Email" rules={[{ type: 'email', required: true, message: 'Por favor, informe um email válido' }]}>
                                    <AutoComplete backfill={true} allowClear={true} onSelect={onSelectEmail} onChange={onChangeEmail} onSearch={onSearchEmail}>
                                        {users.map((x) => (
                                            <AutoComplete.Option key={x.id} value={x.email}>
                                                {x.email}
                                            </AutoComplete.Option>
                                        ))}
                                    </AutoComplete>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="name" label="Nome Completo" rules={[{ required: true, message: 'Por favor, informe o nome' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="phone" label="Celular" rules={[{ required: true, message: 'Por favor, informe o celular' }]}>
                                    <InputMask mask="(99) 99999-9999" placeholder="(__) _____-____" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="product" label="Robô" rules={[{ required: true, message: 'Por favor, selecione um robô' }]}>
                                    <Select>
                                        {/* <Select.Option value={1}>TS Trade</Select.Option>
                                        <Select.Option value={2}>PRO Trade</Select.Option>
                                        <Select.Option value={3}>OnBott</Select.Option>
                                        <Select.Option value={4}>InBott</Select.Option> */}
                                        <Select.Option value={5}>OnBott para MT4</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="free" valuePropName="checked">
                                    <Checkbox checked={false} onChange={onChangeFree}>Robô Gratuito?</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="note" label="Observações" rules={[{ required: noteFree, message: 'Por favor, informe uma observação' }]}>
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item style={{ textAlign: 'right' }}>
                                    <Button type="default" icon={<RollbackOutlined />} style={{ marginRight: '30px' }} onClick={() => push('/robot/license')}>Voltar</Button>
                                    <Button loading={props.userLicense.isProcessing} type="primary" htmlType="submit" icon={<SaveOutlined />}>Salvar</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {props.userLicense.message &&
                        <Alert message={props.userLicense.message} type="error" />
                    }
                </Card>
            </Col>
        </Row>
    )
}

const mapStateToProps = state => ({
    userLicense: state.userLicense,
    users: state.user.users
})

export default connect(mapStateToProps, { findAll, saveAction, push })(EditLicense);