import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { InputMask } from 'components';
import { saveFirstLoginAction } from 'actions/login_action';
import { SaveOutlined } from '@ant-design/icons';
import './Login.scss';

const formItemLayout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 8 },
    },
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 10 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        sm: { span: 24, offset: 0 },
        md: { span: 10, offset: 8 },
    },
};

const FirstLogin = (props) => {
    const { login } = props;
    const { saveFirstLoginAction } = props;

    const onSave = (values) => {
        saveFirstLoginAction(login.user.id, values.email, values.name, values.phone, values.password, false);
    };

    if (login.token) {
        return (
            <div className="login-main">
                <div className="login-center" style={{ width: '50%' }}>
                    <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '20px' }}>
                        Insira seus dados para concluir o cadastro
                    </h2>
                    <Form onFinish={onSave} className="login-form" initialValues={{ email: login.user.email }} {...formItemLayout}>
                        <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Por favor, informe seu email' }]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Por favor, informe seu nome' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="phone" label="Celular" rules={[{ required: true, message: 'Por favor, informe seu celular' }]}>
                            <InputMask mask="(99) 99999-9999" placeholder="(__) _____-____" />
                        </Form.Item>
                        <Form.Item name="password" label="Nova Senha"
                            rules={[{ required: true, message: 'Por favor, informe uma senha' }]}
                            hasFeedback>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item name="confirm" label="Confirme Senha"
                            rules={[
                                { required: true, message: 'Por favor, confirme sua senha' },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('As senha são diferentes');
                                    },
                                }),
                            ]}
                            hasFeedback>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                                Salvar
                        </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    } else {
        return (<Redirect to="/login" />)
    }
}

const mapStateToProps = state => ({
    login: state.login
})

export default connect(mapStateToProps, { saveFirstLoginAction })(FirstLogin);