import React from 'react';
import { Routes } from 'Routes';

import 'antd/dist/antd.css';

//import 'primereact/resources/themes/nova-light/theme.css';
//import 'primereact/resources/primereact.min.css';
//import 'primeicons/primeicons.css';
//import 'primeflex/primeflex.css';
//import '@fullcalendar/core/main.css';
//import '@fullcalendar/daygrid/main.css';
//import '@fullcalendar/timegrid/main.css';
//import 'scss/layout.scss';

export const App = () => <Routes />
