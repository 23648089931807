import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import axios from 'axios';
import createRootReducer from 'reducers'
import thunk from 'redux-thunk';

export const history = createBrowserHistory();

//axios.defaults.baseURL = '/api'
axios.defaults.baseURL = 'https://app.onbott.com/api'
//axios.defaults.baseURL = 'http://localhost:8080/api'
axios.defaults.responseType = 'json';
axios.defaults.contentType = 'json';

export default function configureStore(preloadedState) {
    const store = createStore(
        createRootReducer(history),
        compose(
            applyMiddleware(
                routerMiddleware(history),
                thunk
            ),
        ),
    )

    return store
}