import React, { useEffect } from 'react';
import { Card, Table, Button, Popover } from 'antd';
import { MailOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { findAllAction, sendEmailAction } from 'actions/user_license_action';

const ListLicense = (props) => {
    const { findAllAction, sendEmailAction, push } = props;

    useEffect(() => {
        findAllAction();
    }, [findAllAction])

    const onSendEmail = (row) => {
        sendEmailAction(row.id);
    }

    const templateAdd = (
        <Button type="primary" icon={<PlusOutlined />} onClick={() => push('/robot/license/edit')}>Nova Licença</Button>
    )

    return (
        <Card title="Licenças Cadastradas" bordered={false} extra={templateAdd}>
            <Table dataSource={props.userLicense.licenses} pagination={false}>
                <Table.Column width={250} title="Chave" key="key" dataIndex="key" />
                <Table.Column title="Nome" key="user.name" dataIndex={['user', 'name']} />
                <Table.Column title="Email" key="user.email" dataIndex={['user', 'email']} />
                <Table.Column width={120} title="Robô" key="product.name" dataIndex={['product', 'name']} />
                <Table.Column width={120} title="Ativação" key="activation_date" dataIndex="activation_date" />
                 <Table.Column width={120} title="Válido até" key="due_date" dataIndex="due_date" />
                <Table.Column width={250} title="Suporte" key="user_support.name" dataIndex={['user_support', 'name']} />
                <Table.Column width={50} title="" key="action"
                    render={(row) => (
                        <Popover content="Reenviar Email">
                            <Button type="primary" icon={<MailOutlined />} onClick={() => onSendEmail(row)}>Reenviar Licença</Button>
                        </Popover>
                    )}
                />
            </Table>
        </Card>
    )
}

const mapStateToProps = state => ({
    userLicense: state.userLicense
})

export default connect(mapStateToProps, { findAllAction, sendEmailAction, push })(ListLicense);