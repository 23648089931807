const INITIAL_STATE = {
    error: false,
    message: '',
    licence: {}
}

const termosReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case 'FIND_KEY_SUCCESS':
            return Object.assign({}, state, {
                error: false,
                message: '',
                license: payload.data
            });
        case 'FIND_KEY_FAIL':
            return Object.assign({}, state, {
                error: true,
                message: payload.data.message
            });
        case 'ACCEPT_KEY_SUCCESS':
            return Object.assign({}, state, {
                error: false,
                message: '',
                license: payload.data
            });
        case 'ACCEPT_KEY_FAIL':
            return Object.assign({}, state, {
                error: true,
                message: payload.data.message
            });

        default:
            return state;
    }
}

export default termosReducer;
