import axios from 'axios';
import { push } from 'connected-react-router'

export const loginAction = (email, password) => async (dispatch) => {
    const req = {
        email: email,
        password: password
    };

    try {
        dispatch({ type: 'LOGIN' })
        const res = await axios.post('/login', req);
        const token = res.data.token;

        axios.defaults.headers['Authorization'] = 'Bearer ' + token;

        await dispatch({
            type: 'LOGIN_TOKEN',
            payload: res
        })

        const resLogged = await axios.get('/login/logged');

        console.log(resLogged)

        dispatch({
            type: 'LOGIN_SUCCESS',
            payload: resLogged.data
        });

        const { user } = resLogged.data || {};
        if (user && user.firstLogin) {
            dispatch(push('/first-login'))
        } else {
            dispatch(push('/'))
        }

    } catch (e) {
        if (e.response) {
            console.log(e.response)
            dispatch({
                type: 'LOGIN_FAIL',
                payload: e.response.data
            });
        } else {
            dispatch({
                type: 'LOGIN_FAIL',
                payload: { message: "Ocorreu um erro. Tente novamente." }
            });
        }
    }
}

export const saveFirstLoginAction = (id, email, name, phone, password, firstLogin) => async (dispatch) => {
    let req = {
        id: id,
        email: email,
        name: name,
        phone: phone,
        password: password,
        firstLogin: firstLogin
    };

    const res = await axios.post('/login/firstlogin', req);

    dispatch({
        type: 'LOGIN_SUCCESS',
        payload: res
    });

    dispatch(push('/'));

    return res;
}